import React, { useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import GraphIcon from "../../Images/GraphIcon.svg";
import { httpsRequest } from "../../Features/Auth/config";

function DistrictBDO() {
  const [userList,setUserList] = useState([])
  const districtInformation = [
    { fieldName: "Total District B.D.O", value: "500" },
    { fieldName: "Total Farmer", value: "6500" },
    { fieldName: "Total Village Cordinator", value: "800" },
    { fieldName: "Farm Area in Bigha", value: "800" },
  ];

  useEffect(() => {
    GetAllUser();
  }, []);

  const GetAllUser = async () => {
    try {
      const response = await httpsRequest("district-bdo/get-total-user-on-district-bdo","get")
      console.log(response)
      if (response.status === 200) {
          setUserList(response.data)
      }
    } catch (error) {
      
    }   
  }
  return (
      <div className="flex flex-col w-full py-5 ps-5 pe-10">
        <div className="grid grid-cols-4 gap-5">
          {userList.map((detail) => (
            <>
              <div className="border-2 border-[#75A651] flex justify-between py-4 px-3 rounded-xl">
                <div className="">             
                  <p className="text-5xl">{detail.noOfUser}</p>
                  <p className="mt-2.5 text-base">{detail.userRole}</p>
                </div>
                <img src={GraphIcon} className="max-w-16 max-h-16" />
              </div>
            </>
          ))}
        </div>
      </div>
  );
}

export default DistrictBDO;
