import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";
import axios from "axios";
const Base_Url = process.env.React_App_Base_Url;

function UpdateFarmer() {
  const token = sessionStorage.getItem("token")
  const location = useLocation();
  const { state: value } = location || {};
  const navigate = useNavigate();
  const [userValue, setUserValue] = useState(value);
  const [showLoader, setShowLoader] = useState(false)
  const [formErrors, setFormErrors] = useState({
    address: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    category: "",
    gender: "",
    dob: "",
    mobile_number: "",
    email: "",
    aadhaar_number: "",
    pan_no: "",
    father_name: "",
    mother_name: "",
  });

  const FormDetail = (e) => {
    const { name, value, type } = e.target;
    if (value == "") {
      setFormErrors({ ...formErrors, [name]: `${name} can't be empty` });
    }
    setUserValue({
      ...userValue,
      [name]: type === "number" ? Number(value) : value,
    });
  };

  const FormSubmit = async () => {
    try {
      setShowLoader(true)
      const response = await httpsRequest(`v3/village-coordinator/farmer/${userValue.farmer_id}`, "put", userValue);
      if (response.status == 200) {
        setShowLoader(false)
        navigate("/dashboard");
      }
    } catch (error) {
      setShowLoader(false)
      console.log("error message========>>>>>>>>>>", error);
    }
  };

  return (
    <>
    {showLoader && <Loader/>}
    <div className="py-5 ps-5 pe-10 w-full h-screen overflow-y-scroll">
      <p className="font-medium text-xl">Farmer Information</p>
      <p className="text-3xl font-medium mt-16">Farmer Details</p>
      

      <p className="font-medium text-xl my-8">Personal Details</p>
      <div className="grid grid-cols-3 gap-y-5 gap-x-10 mt-9">
        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Frist Name:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="first_name"
            defaultValue={userValue?.first_name}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.first_name && (
            <p className="text-xs text-red-500">{formErrors.first_name}</p>
          )}
        </div>
        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Middle Name:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="middle_name"
            defaultValue={userValue?.middle_name}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.middle_name && (
            <p className="text-xs text-red-500">{formErrors.middle_name}</p>
          )}
        </div>
        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Last Name:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="last_name"
            defaultValue={userValue?.last_name}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.last_name && (
            <p className="text-xs text-red-500">{formErrors.last_name}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Category:
          </p>
          <select
            onChange={(e) => FormDetail(e)}
            name="category"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            defaultValue={userValue?.category}
          >
            <option value="">{userValue?.category}</option>
            <option value="general">General</option>
            <option value="obc">OBC</option>
            <option value="st">ST</option>
            <option value="sc">SC</option>
          </select>
          {!userValue.category && (
            <p className="text-xs text-red-500">{formErrors.category}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Gender:
          </p>
          <select
            onChange={(e) => FormDetail(e)}
            name="gender"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            defaultValue={userValue?.gender}
          >
            <option value="">{userValue?.gender}</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          {!userValue.gender && (
            <p className="text-xs text-red-500">{formErrors.gender}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            DOB:
          </p>
          <input
            type="date"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="dob"
            defaultValue={userValue?.dob}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.dob && (
            <p className="text-xs text-red-500">{formErrors.dob}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Mobile Number:
          </p>
          <input
            type="number"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="mobile_number"
            defaultValue={userValue.mobile_number}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.mobile_number && (
            <p className="text-xs text-red-500">{formErrors.mobile_number}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Email:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="email"
            defaultValue={userValue?.email}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.email && (
            <p className="text-xs text-red-500">{formErrors.email}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Aadhar Number:
          </p>
          <input
            type="number"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="aadhaar_number"
            defaultValue={userValue?.aadhaar_number}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.aadhar_number && (
            <p className="text-xs text-red-500">{formErrors.aadhar_number}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Pan Number:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="pan_number"
            defaultValue={userValue?.pan_number}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.pan_number && (
            <p className="text-xs text-red-500">{formErrors.pan_number}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Father Name:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="father_name"
            defaultValue={userValue?.father_name}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.father_name && (
            <p className="text-xs text-red-500">{formErrors.father_name}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Mother Name:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="mother_name"
            defaultValue={userValue?.mother_name}
            onChange={(e) => FormDetail(e)}
          />
          {!userValue.mother_name && (
            <p className="text-xs text-red-500">{formErrors.mother_name}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-3 gap-y-5 gap-x-10 mt-9">
        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Address:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="address"
            defaultValue={userValue?.address}
            onChange={(e) => FormDetail(e)}
            placeholder="Address"
          />
          {!userValue.address && (
            <p className="text-xs text-red-500">{formErrors.address}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            City:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="city_name"
            defaultValue={userValue?.city_name}
            onChange={(e) => FormDetail(e)}
            placeholder="City"
          />
          {!userValue.city_name && (
            <p className="text-xs text-red-500">{formErrors.city_name}</p>
          )}
        </div>

        <div className="block">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Pin:
          </p>
          <input
            type="text"
            className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            name="pin_number"
            defaultValue={userValue?.pin_number}
            onChange={(e) => FormDetail(e)}
            placeholder="Pin"
          />
          {!userValue.pin_number && (
            <p className="text-xs text-red-500">{formErrors.pin_number}</p>
          )}
        </div>
      </div>
      <div className="flex justify-center gap-5 mt-5">
        <button
          type="button"
          onClick={() => navigate("/dashboard")}
          className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={FormSubmit}
          className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
        >
          Submit
        </button>
      </div>
    </div>
    </>
  );
}

export default UpdateFarmer;
