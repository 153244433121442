import React, { useState } from "react";
import LoginBG from "../../Images/RegistrationBG.jpg";
import { useNavigate } from "react-router-dom";

function Welcome() {
    const navigate = useNavigate();

    const SubmitUser = (role) => {
        navigate('/login',{state: role,replace: true})
    }
  return (
    <>
      <div className="flex justify-between">
        <div className="w-full max-h-full">
          <img src={LoginBG} className="w-dvw h-dvh" />
        </div>

        <div className="registrationBackground w-1/2 flex-col flex items-center justify-around bg-gray-100 w-full">
          <div>
            <h1 className="text-4xl">Admin OnBoarding</h1>
            <p className="text-base font-medium mt-3">
              Get started by logging into your account
            </p>
          </div>

          <div className="flex flex-col gap-10">
            <button className="bg-[#0391CF] text-white py-2 min-w-64 rounded-lg" value={1} onClick={(e) => SubmitUser(e.target.value)}>
              Super Ammin Login
            </button>
            <button className="bg-[#89BB64] text-white py-2 min-w-64 rounded-lg" value={2} onClick={(e) => SubmitUser(e.target.value)}>Admin Login</button>
            <button className="bg-[#0391CF] text-white py-2 min-w-64 rounded-lg" value={3} onClick={(e) => SubmitUser(e.target.value)}>State BDO</button>
            <button className="bg-[#89BB64] text-white py-2 min-w-64 rounded-lg" value={4} onClick={(e) => SubmitUser(e.target.value)}>District BDO</button>
            <button className="bg-[#0391CF] text-white py-2 min-w-64 rounded-lg" value={5} onClick={(e) => SubmitUser(e.target.value)}>Village Coordinator</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
