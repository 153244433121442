import axios from 'axios';
const base_url = process.env.React_App_Base_Url;
const api = axios.create({
  baseURL: base_url,
});
api.interceptors.response.use(
  (response) => {
    if (response.data && response.data.data) {
      return { ...response, data: response?.data?.data, token: response?.data?.token };
      
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;