import React, { useEffect, useState } from "react";
import PublicIcon from "../../Images/PublicIcon.svg";
import DataTable from "../DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";

function SuperAdminDashboard() {
  const navigate = useNavigate();
  const [adminList, setAdminList] = useState([]);
  const [noOfUser, setNoOfUser] = useState([]);
  const tableHeaders = ["User status", "Admin Name", "Gmail", "Contact"];

  useEffect(() => {
    GetAdmin();
    GetAllUser();
  }, []);

  const GetAdmin = async () => {
    try {
      const response = await httpsRequest("v1/sa/admin", "get");
      if (response.status == 200) {
        setAdminList(response?.data);
      } else {
        console.log("Error Ocurred", response?.data);
      }
    } catch (error) {
      console.error("didn't get the list of Admin");
    }
  };

  const GetAllUser = async () => {
    try {
      const response = await httpsRequest("v1/sa/get-total-user", "get");
      if (response.status === 200) {
        setNoOfUser(response.data);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="flex flex-col w-full py-5 ps-5 pe-10 md:pe-4 sm:pe-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
          {noOfUser.map((detail, index) => (
            <div
              key={index}
              className="border-2 border-[#75A651] flex justify-between py-2 px-3 rounded-xl"
            >
              <div>
                <p className="text-3xl md:text-5xl">{detail.noOfUser}</p>
                <p className="mt-2.5 text-sm md:text-base">
                  Total {detail.userRole}
                </p>
              </div>
              <img
                src={PublicIcon}
                className="max-w-12 max-h-12 md:max-w-16 md:max-h-16"
              />
            </div>
          ))}
        </div>

        <div className="border-2 rounded-xl border-[#DAE1E9] mt-5 w-full p-3 md:p-2">
          <div className="flex flex-row justify-between items-center">
            <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl">
              Admin List
            </h1>
            <div className="mt-2 md:mt-0 flex gap-3 justify-center items-center">
              <button
                className="rounded-lg bg-orange-500 py-1.5 sm:py-2 md:py-2 lg:py-2.5 px-2 sm:px-3 md:px-4 lg:px-5 text-sm sm:text-base md:text-lg lg:text-xl text-white"
                onClick={() => navigate("/manage-admin")}
              >
                Manage
              </button>
              <button
                className="rounded-lg bg-orange-500 py-1.5 sm:py-2 md:py-2 lg:py-2.5 px-2 sm:px-3 md:px-4 lg:px-5 text-sm sm:text-base md:text-lg lg:text-xl text-white ml-0 md:ml-3 mt-2 md:mt-0"
                onClick={() => navigate("/admin-onboard")}
              >
                Add New Admin
              </button>
            </div>
          </div>

          <DataTable
            data={adminList}
            headerOption={tableHeaders}
            limit={5}
            itemsPerPage={5}
          />
        </div>
      </div>
    </>
  );
}

export default SuperAdminDashboard;
