import React, { useState } from "react";
import { httpsRequest } from "../../Features/Auth/config";
import { useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";

function SoilTesting() {
  const location = useLocation();
  const { service_id } = location.state || {};
  const [farmerId, setFarmerId] = useState();
  const [aadharNo, setAadharNo] = useState();
  const [farmList, setFarmList] = useState();
  const [farmId, setFarmId] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const getFarmDetail = async () => {
    try {
      setShowLoader(true);
      const response = await httpsRequest(
        `v3/village-coordinator/farm/${farmerId}`,
        "get"
      );
      if (response.status === 200) {
        setShowLoader(false)
        setFarmList(response.data);  
      }      
    } catch (error) {
      setShowLoader(false);
    }
  };

  const submitSoilrequest = async () => {
    try {
      const formData = {
        farmer_id: Number(farmerId),
        aadhaar_number: Number(aadharNo),
        service_id: service_id,
        farm_id: Number(farmId),
      };
      setShowLoader(true)
      const response = await httpsRequest(
        "v3/village-coordinator/service/soil-testing",
        "post",
        formData
      );
      if (response.status === 201) {
        setShowLoader(false)
        setFarmerId("");
        setFarmId("");
        setAadharNo("");
      }
    } catch (error) {
      setShowLoader(false)
    }
  };
  return (
    <>
    {showLoader && <Loader/>}
      <div className="flex flex-col w-full py-5 ps-5 pe-10">
        <div className="flex justify-between border-b border-color-[#F1F4F8]">
          <p className="font-medium text-xl mb-4">Soil Testing Request</p>
        </div>
        <div className="flex flex-col md:flex-row gap-5 mt-5">
          <div className="sm:w-full md:max-w-96">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Farmer ID
            </p>
            <input
              type="text"
              name="farmer_id"
              value={farmerId}
              onChange={(e) => setFarmerId(e.target.value)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>

          <div className="sm:w-full md:max-w-96">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Aadhar Card
            </p>
            <input
              type="text"
              name="aadhaar_card"
              value={aadharNo}
              onChange={(e) => setAadharNo(e.target.value)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>

          <button
            className="rounded-lg bg-blue-500 py-3 px-5 mt-9 text-white self-center"
            type="button"
            onClick={getFarmDetail}
          >
            Get Farmer Land Detail
          </button>
        </div>

        <div className="sm:w-full md:max-w-96 mt-5">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Khasra Number
          </p>

          <select
            className="w-full p-2 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            name="farm_id"
            value={farmId}
            onChange={(e) => setFarmId(e.target.value)}
          >
            {farmList?.map((list) => (
              <option>{list.farm_id}</option>
            ))}
          </select>
        </div>

        <button
          className="rounded-lg bg-orange-500 py-3 px-5 mt-9 text-white self-center"
          type="button"
          onClick={submitSoilrequest}
        >
          Submit Request
        </button>
      </div>
    </>
  );
}

export default SoilTesting;
