import React, { useState } from "react";
import Loader from "../Loader/Loader";

function WorkforceRequirement() {
  const [showLoader, setShowLoader] = useState(false)

  const FormSubmit = () => {
    setShowLoader(true)
  }
  return (
    <>
    {showLoader && <Loader/>}
      <div className="py-2 ps-5 pe-10">
        <p className="font-medium text-xl border-b-[1px] py-2">
          Workforce Requirement
        </p>
        <p className="text-xl font-medium mt-11 mb-2">Workforce Requirement Information:</p>
      </div>

      <div className="grid grid-cols-3 gap-y-5 ps-5 pe-10 mt-3">
        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            First Name
          </p>
          <input
            type="text"
            name="first_name"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Middle Name
          </p>
          <input
            type="text"
            name="middle_name"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Last Name
          </p>
          <input
            type="text"
            name="last_name"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Season
          </p>
          <input
            type="text"
            name="season"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Type of Farming
          </p>
          <input
            type="text"
            name="type_of_farming"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Number of Worker Needed
          </p>
          <input
            type="text"
            name="number_of_worker_needed"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Start Date
          </p>
          <input
            type="text"
            name="start_date"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            End Date
          </p>
          <input
            type="text"
            name="end_date"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Farm Size
          </p>
          <input
            type="text"
            name="farm_size"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Type Of Farming
          </p>
          <input
            type="text"
            name="type_of_farming"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Pricing Strategy
          </p>
          <input
            type="text"
            name="pricing_strategy"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Type Of Farming
          </p>
          <input
            type="text"
            name="type_of_farming"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Pricing Strategy
          </p>
          <input
            type="text"
            name="pricing_strategy"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>
      </div>

      <div className="flex justify-center gap-5 mt-12">
        <button
          type="button"
          //   onClick={() => navigate("/dashboard")}
          className="rounded-lg bg-[#6E7C87] p-2 px-7 text-white"
        >
          Cancel
        </button>
        <button
          type="button"
            onClick={FormSubmit}
          className="rounded-lg bg-[#0391CF] p-2 px-7 text-white"
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default WorkforceRequirement;
