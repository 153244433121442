import React, { useEffect, useState } from "react";
import ServiceImage from "../../Images/Service.svg";
import { useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";

function AllServices() {
  const navigate = useNavigate();
  const [allServices, setAllServices] = useState([]);
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    GetAllServices();
  }, []);

  const GetAllServices = async () => {
    try {
      setShowLoader(true)
      const response = await httpsRequest("common/service", "get");
      if (response.status == 200) {
        setShowLoader(false)
        setAllServices(response?.data);
      }
    } catch (error) {
      setShowLoader(false)
    }
  };

  const handelServiceChange = (service) => {
    switch (service.service_name) {
      case "Farmer Valuation":
        navigate("/farm-valuation", {
          state: { service_id: service.service_id },
        });
        break;
      case "Crops Valuation":
        navigate("/crop-valuation", {
          state: { service_id: service.service_id },
        });
        break;
      case "Soil Testing":
        navigate("/soil-testing",{state: { service_id: service.service_id}});
      default:
        console.log("you selected the services", service);
        break;
    }
  };

  return (
    <>
    {showLoader && <Loader/>}
      <div className="flex flex-col w-full py-5 ps-5 pe-10">
        <div className="flex justify-between border-b  border-color-[#F1F4F8]">
          <p className="font-medium text-xl mb-4">All Services</p>
          <button
            className="bg-[#75A651] mb-4 py-2 text-white px-4 rounded-lg flex items-center gap-2"
            type="button"
            // onClick={() => navigate("/add-farm")}
          >
            <div className="flex items-center justify-center w-5 h-5 bg-green-800 rounded-full">
              <span>+</span>
            </div>
            Add New Services
          </button>
        </div>

        <div className="grid grid-cols-4 gap-10 mt-7">
          {allServices.map((service) => (
            <div
              className=" flex flex-col justify-around items-center sm:text-center bg-[#0391CF] p-2 gap-2 rounded-lg text-white cursor-pointer"
              onClick={() => handelServiceChange(service)}
            >
              <img src={ServiceImage} className="max-w-16 max-h-16" />
              <p>{service.service_name}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default AllServices;
