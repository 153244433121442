import React, { useRef, useState } from "react";
import { CiUser } from "react-icons/ci";
import { LuMapPin } from "react-icons/lu";
import { IoDocumentsOutline } from "react-icons/io5";
import UploadComponent from "../UploadComponent/UploadComponent";
import { useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import axios from "axios";
import Loader from "../Loader/Loader";
const Base_Url = process.env.React_App_Base_Url;
function UpdateProfile() {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [profiledetail, setProfileDetail] = useState({
    profile_img: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    father_name: "",
    mother_name: "",
    age: "",
    country: "",
    state: "",
    district: "",
    pin_code: "",
    address: "",
    aadhaar_card_file: "",
    aadhaar_number: "",
    pan_card_file: "",
    pan_number: "",
  });
  const [formErrors, setFormErrors] = useState({
    profile_img: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    country: "",
    state: "",
    district: "",
    pin_code: "",
    address: "",
    aadhaar_card_file: "",
    aadhaar_number: "",
    pan_card_file: "",
    pan_number: "",
  });
  const [previewProfileImage, setPreviewProfileImage] = useState(null);
  const [previewAadharImage, setPreviewAadharImage] = useState(null);
  const [previewPanImage, setPreviewPanImage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const FormDetail = (e) => {
    const { value, name } = e.target;
    setProfileDetail({ ...profiledetail, [name]: value });
  };

  const handleFileSelect = (name, file) => {
    console.log("name", name, "file", file);
    setProfileDetail({ ...profiledetail, [name]: file });
    if (name === "profile_img") {
      setPreviewProfileImage(URL.createObjectURL(file));
    } else if (name === "aadhaar_card_file") {
      setPreviewAadharImage(URL.createObjectURL(file));
    } else {
      console.log("hell,file", file);
      setPreviewPanImage(URL.createObjectURL(file));
    }
  };

  const handleButtonClick = () => {
    console.log("test value");
    fileInputRef.current.click();
  };

  const UpdateProfile = async () => {
    try {
      const personal_details = {
        father_name: profiledetail.father_name,
        mother_name: profiledetail.mother_name,
        age: Number(profiledetail.age),
        address: profiledetail.address,
        country: profiledetail.country,
        state: profiledetail.state,
        district: profiledetail.district,
        pin_code: profiledetail.pin_code,
      };

      const formData = new FormData();
      formData.append("profile_img", profiledetail.profile_img);
      formData.append("first_name", profiledetail.first_name);
      formData.append("middle_name", profiledetail.middle_name);
      formData.append("last_name", profiledetail.last_name);
      formData.append("mobile_number", profiledetail.mobile_number);
      formData.append("aadhaar_card_file", profiledetail.aadhaar_card_file);
      formData.append("aadhaar_number", profiledetail.aadhaar_number);
      formData.append("pan_number", profiledetail.pan_number);
      formData.append("pan_card_file", profiledetail.pan_card_file);

      for (const key in personal_details) {
        formData.append(`personal_details[${key}]`, personal_details[key]);
      }

      setShowLoader(true);
      // const response = await httpsRequest(
      //   "v2/admin/profile-update",
      //   "put",
      //   formData
      // );

      const response = await axios.put(
        `${Base_Url}/v2/admin/profile-update`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status == 200) {
        setShowLoader(false);
        navigate("/dashboard");
      }
    } catch (error) {
      setShowLoader(false);
      console.error(error.response.data.message);
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      <div className="p-5 w-full">
        <h3 className="text-xl font-medium">Profile Setting</h3>
        <div className="flex items-center gap-2 mt-2 mb-5">
          <CiUser />
          <p className="font-medium text-base">User Information</p>
        </div>

        <div className="flex flex-col sm:flex-row items-center">
          <div className="border border-dashed border-gray-300 w-32 h-32 flex items-center justify-center relative">
            {previewProfileImage ? (
              <img
                src={previewProfileImage}
                alt="Profile Preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <span className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center text-gray-500">
                Profile Photo
              </span>
            )}

            <input
              id="profile_img"
              type="file"
              ref={fileInputRef}
              name="profile_img"
              accept="image/*"
              className="hidden"
              onChange={(e) =>
                handleFileSelect("profile_img", e.target.files[0])
              }
            />
            <button
              onClick={handleButtonClick}
              className="absolute inset-0 w-full h-full bg-gray-200 opacity-0 hover:opacity-50 focus:opacity-50"
            >
              <span className="sr-only" htmlFor="profile_img">
                Change Profile Photo
              </span>
            </button>
          </div>
          <div className="ml-0 sm:ml-4 mt-4 sm:mt-0">
            <button
              onClick={handleButtonClick}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Change Image
            </button>
            <div className="text-sm text-gray-500 mt-2">
              For better preview recommended size is 450px X 450px. Max size 5MB
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
          {[
            {
              label: "First Name",
              name: "first_name",
              value: profiledetail.first_name,
              error: formErrors.first_name,
            },
            {
              label: "Middle Name",
              name: "middle_name",
              value: profiledetail.middle_name,
              error: formErrors.middle_name,
            },
            {
              label: "Last Name",
              name: "last_name",
              value: profiledetail.last_name,
              error: formErrors.last_name,
            },
            {
              label: "Phone Number",
              name: "mobile_number",
              value: profiledetail.mobile_number,
              error: formErrors.mobile_number,
            },
            {
              label: "Father Name",
              name: "father_name",
              value: profiledetail.father_name,
              error: formErrors.father_name,
            },
            {
              label: "Mother Name",
              name: "mother_name",
              value: profiledetail.mother_name,
              error: formErrors.mother_name,
            },
            {
              label: "Age",
              name: "age",
              value: profiledetail.age,
              error: formErrors.age,
            },
            {
              label: "Pan Number",
              name: "pan_number",
              value: profiledetail.pan_number,
              error: formErrors.pan_number,
            },
            {
              label: "Aadhar Number",
              name: "aadhaar_number",
              value: profiledetail.aadhaar_number,
              error: formErrors.aadhaar_number,
            },
          ].map(({ label, name, value, error }) => (
            <div className="block" key={name}>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                {label}:
              </p>
              <input
                type="text"
                className="w-full p-3 border-solid border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                name={name}
                value={value}
                onChange={(e) => FormDetail(e)}
              />
              {!value && <p className="text-xs text-red-500">{error}</p>}
            </div>
          ))}
        </div>

        <div className="flex items-center gap-2 mt-5 mb-5">
          <LuMapPin />
          <p className="font-medium text-base">Our Address</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
          {[
            {
              label: "Country",
              name: "country",
              value: profiledetail.country,
              error: formErrors.country,
            },
            {
              label: "State",
              name: "state",
              value: profiledetail.state,
              error: formErrors.state,
            },
            {
              label: "District",
              name: "district",
              value: profiledetail.district,
              error: formErrors.district,
            },
            {
              label: "Postal Code",
              name: "pin_code",
              value: profiledetail.pin_code,
              error: formErrors.pin_code,
            },
            {
              label: "Address",
              name: "address",
              value: profiledetail.address,
              error: formErrors.address,
            },
          ].map(({ label, name, value, error }) => (
            <div className="block" key={name}>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                {label}:
              </p>
              <input
                type="text"
                className="w-full p-3 border-solid border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                name={name}
                value={value}
                onChange={(e) => FormDetail(e)}
              />
              {!value && <p className="text-xs text-red-500">{error}</p>}
            </div>
          ))}
        </div>

        <div className="flex items-center gap-2 mt-5 mb-5">
          <IoDocumentsOutline />
          <p className="font-medium text-base">Documents</p>
        </div>

        <div className="flex flex-col sm:flex-row gap-5">
          {previewPanImage ? (
            <div className="flex flex-col items-center">
              <img
                src={previewPanImage}
                alt="Pan Card"
                className="w-32 h-32 object-cover"
              />
              <span className="mt-2 text-base">Pan Card</span>
            </div>
          ) : (
            <UploadComponent
              title="Pan Card"
              name="pan_card_file"
              onFileSelect={handleFileSelect}
            />
          )}
          {previewAadharImage ? (
            <div className="flex flex-col items-center">
              <img
                src={previewAadharImage}
                alt="Aadhar Card"
                className="w-32 h-32 object-cover"
              />
              <span className="mt-2 text-base">Aadhar Card</span>
            </div>
          ) : (
            <UploadComponent
              title="Aadhar Card"
              name="aadhaar_card_file"
              onFileSelect={handleFileSelect}
            />
          )}
        </div>

        <div className="flex justify-center">
          <button
            className="rounded-lg bg-orange-500 p-2 px-5 text-white mt-5 self-center"
            type="button"
            onClick={UpdateProfile}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
