import React, { useEffect, useState } from "react";
import SuperAdminDashboard from "../SuperAdmin/SuperAdminDashboard";
import AdminDashboard from "../Admin/AdminDashboard";
import StateBDO from "../StateBDO/StateBDO";
import DistrictBDO from "../DistrictBDO/DistrictBDO";
import VillageCoordinator from "../VillageCoordinator.jsx/VillageCoordinator";

function Dashboard() {
  const storedUser = localStorage.getItem('userValue');
  const userValue = storedUser ? JSON.parse(storedUser) : null
 
  const renderedDashBoard = () =>{
    switch (userValue.role) {
      case 1:
        return <SuperAdminDashboard/>;        
      case 2:
        return <AdminDashboard/>;
      case 3:
        return <StateBDO/>;
      case 4: 
        return <DistrictBDO/>;
      case 5:
          return <VillageCoordinator/>
      default:
        return <h1>Hello world</h1>
        break;
    }
  }
  return (
    <>
      {renderedDashBoard()}
    </>
  );
}

export default Dashboard;
