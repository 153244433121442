import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Components/Login/Login";
import AdminOnboarding from "./Components/AdminOnboarding/AdminOnboarding";
import CreatePassword from "./Components/CreatePassword/CreatePassword";
import UpdateAdmin from "./Components/UpdateAdmin/UpdateAdmin";
import Welcome from "./Components/Welcome/Welcome";
import Dashboard from "./Components/Dashboard/Dashboard";
import AdminList from "./Components/Admin/AdminList";
import AddUser from "./Components/AddUser/AddUser";
import UserProfile from "./Components/UserProfile/UserProfile";
import UpdateProfile from "./Components/UpdateProfile/UpdateProfile";
import UserList from "./Components/UserList/UserList";
import AddFarmer from "./Components/Farmer/AddFarmer";
import AddFarm from "./Components/Farm/AddFarm";
import FarmerHistory from "./Components/Farmer/FarmerHistory";
import UpdateFarmer from "./Components/UpdateFarmer/UpdateFarmer";
import FarmList from "./Components/Farm/FarmList";
import EditFarm from "./Components/Farm/EditFarm";
import AllServices from "./Components/Services/AllServices";
import ServiceDetail from "./Components/Services/ServiceDetail";
import Layout from "./Components/Layout/Layout";
import Cookies from 'js-cookie';
import CropValuation from "./Components/CropValuation/CropValuation";
import FarmEvaluation from "./Components/Services/FarmEvaluation";
import CropValuationCertificate from "./Components/CropValuation/CropValuationCertificate";
import NotificationComponent from "./Components/DistrictBDO/Notification";
import SoilTesting from "./Components/Services/SoilTesting";
import AgriculturePlanning from "./Components/Services/AgriculturePlanning";
import GovermentSchemes from "./Components/Services/GovermentSchemes";
import ClusterSupport from "./Components/Services/ClusterSupport";
import WorkforceRequirement from "./Components/Services/WorkforceRequirement";


const ProtectedRoutes = ({ Component, ...rest }) => {
  const isAuthenticated = Cookies.get('authToken') ? Cookies.get('authToken') : sessionStorage.getItem("token");
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

function App() {
  return (
      <Router>
        <Routes>
          {/* Common Routes */}
          <Route index Component={Welcome} />
            <Route path="/login" Component={Login} />
            <Route path="/auth/create-password" Component={CreatePassword} />
            <Route path="/update-admin" Component={UpdateAdmin} />
            <Route exact path="/admin-onboard" Component={AdminOnboarding} />


          <Route path="/" Component={Layout}>

          {/* <Route path="/dashboard" Component={Dashboard} /> */}
          <Route path="/dashboard" element={<ProtectedRoutes Component={Dashboard}/>} />
            <Route path="/user-profile" element={<ProtectedRoutes Component={UserProfile}/>} />
            <Route path="/manage-user" element={<ProtectedRoutes Component={UserList}/>} />
            <Route path="/add-farmer" element={<ProtectedRoutes Component={AddFarmer}/>} />
            <Route path="/add-farm" element={<ProtectedRoutes Component={AddFarm}/>} />
            <Route path="/farmer-history" element={<ProtectedRoutes Component={FarmerHistory}/>} />
            <Route path="/update-farmer" element={<ProtectedRoutes Component={UpdateFarmer}/>} />
            <Route path="/view-farm" element={<ProtectedRoutes Component={FarmList} />}/>
            <Route path="/update-farm" element={<ProtectedRoutes Component={EditFarm}/>} />
            <Route path="/services" element={<ProtectedRoutes Component={AllServices}/>} />
            <Route path="/services/:id" element={<ProtectedRoutes Component={ServiceDetail}/>} />
            <Route path="/farm-valuation" element={<ProtectedRoutes Component={FarmEvaluation} />}/>         
            <Route path="/manage-admin" element={<ProtectedRoutes Component={AdminList} />}/>
            <Route path="/add-user" element={<ProtectedRoutes Component={AddUser} />}/>
            <Route path="/update-profile"  element={<ProtectedRoutes Component={UpdateProfile} />}/>
            <Route path="/crop-valuation" element={<ProtectedRoutes Component={CropValuation}/>}/>

            <Route path="/soil-testing" Component={SoilTesting}/>
            <Route path="/agriculture-planning" Component={AgriculturePlanning}/>
            <Route path="/government-schemes" Component={GovermentSchemes}/>
            <Route path="/cluster-support" Component={ClusterSupport}/>
            <Route path="/workforce-requirement" Component={WorkforceRequirement}/>

            {/* Routes For District BDO User */}
            <Route path="/crop-valuation-certificate" element={<ProtectedRoutes Component={CropValuationCertificate}/>}/>
            <Route path="/notification" Component={NotificationComponent}/>
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
