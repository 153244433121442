import React, { useEffect, useState } from "react";
import PublicIcon from "../../Images/PublicIcon.svg";
import DataTable from "../DataTable/DataTable";
import axios from "axios";
import SideBar from "../SideBar/SideBar";
import { useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
const Base_Url = process.env.React_App_Base_Url;

function AdminDashboard() {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [updatedList, setUpdatedList] = useState(userList);
  const [selectUserType,setSelectUserType] = useState('village_coordinator')
  const [noOfUser, setNoOfUser] = useState([]);
  const administrationDetails = [
    { userRole: "Admin", noOfUser: "10" },
    { userRole: "StateBDO", noOfUser: "40" },
    { userRole: "DistrictBDO", noOfUser: "560" },
    { userRole: "Farmer", noOfUser: "5000" },
  ];

  const tableHeaders = ["User status", "Admin Name", "Gmail", "Contact"];

  useEffect(() => {
    GetUser();    
    GetAllUser();
  }, []);

  const GetUser = async () => {
    try {
      const response = await httpsRequest("v2/admin/user", "get");
      if (response.status == 200) {
        setUserList(response?.data);
      } else {
        console.log("Error Ocurred", response?.data);
      }
    } catch (error) {
      console.error("didn't get the list of Admin");
    }
  };

  const GetAllUser = async () => {
    try {
      const response = await httpsRequest("v1/sa/get-total-user", "get");
      if (response.status === 200) {
        setNoOfUser(response.data);
      }
    } catch (error) {}
  };


  const AddUser = (role) => {
    navigate("/add-user", { state: role });
  };

  return (
    <>
        <div className="flex flex-col w-full py-5 ps-5 pe-10 sm:p-4 md:p-3">
          <div className="grid grid-cols-4 gap-5">
            {noOfUser.map((detail) => (
              <>
                <div className="border-2 border-[#75A651] flex justify-between py-4 px-3 rounded-xl">
                  <div className="">
                    <p className="text-5xl">{detail.noOfUser}</p>
                    <p className="mt-2.5 text-base">{detail.userRole}</p>
                  </div>
                  <img src={PublicIcon} className="max-w-16 max-h-16" />
                </div>
              </>
            ))}
          </div>

          <div className="table border-2 rounded-xl border-[#DAE1E9] mt-5 w-full p-5">
            <div className="flex justify-between">
              <h1 className="text-2xl ">User List</h1>
              <div className="gap-3 flex">
                <select
                className="border-[1px] p-2 rounded-lg border-[#DAE1E9]"
                  onChange={(e) => setSelectUserType(e.target.value)}
                > <option value="">Select</option>
                  <option value="village_coordinator">Village Coordinator</option>
                  <option value="state_bdo">State BDO</option>
                  <option value="district_bdo">District BDO</option>
                  
                </select>
                <button
                  className="rounded-lg bg-orange-500 py-2 px-3 text-white"
                  onClick={() => navigate("/manage-user",{state: {selectUserType:selectUserType, userList:userList}})}
                >
                  Manage
                </button>
              </div>
            </div>
            <DataTable data={userList} headerOption={tableHeaders} limit={5} itemsPerPage={5}/>
          </div>
        </div>
    </>
  );
}

export default AdminDashboard;
