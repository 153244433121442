import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginBG from "../../Images/RegistrationBG.jpg";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";

function UpdateAdmin() {
  const location = useLocation();
  const { state: adminDetail } = location || {};
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({
    first_name: adminDetail?.first_name,
    middle_name: adminDetail?.middle_name,
    last_name: adminDetail?.last_name,
    email: adminDetail?.email,
    mobile_number: Number(adminDetail?.mobile_number),
  });
  const [errors, setErrors] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
  });
  const [showLoader, setShowLoader] = useState(false);

  const fieldNames = {
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    email: "Email",
    mobile_number: "Mobile Number",
  };
  const FormDetail = (e) => {
    const { name, value } = e.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  const FormSubmit = async () => {
    const formErrors = {};

    Object.keys(errors).forEach((key) => {
      if (!userDetail[key]) {
        const fieldName = fieldNames[key] || key;
        formErrors[key] = `${fieldName} Can't be empty`;
      }
    });
    setErrors(formErrors);

    if (Object.keys(errors).length === 0) {
      try {
        setShowLoader(true);
        const response = await httpsRequest(
          `v1/sa/admin/${adminDetail.admin_id}`,
          "put",
          userDetail
        );
        if (response.status === 200) {
          setShowLoader(false);
          navigate("/manage-admin");
        }
      } catch (error) {
        setShowLoader(false);
        console.log("Sign In failed:", error);
      }
    }
  };
  return (
    <>
      {console.log("errors", errors)}
      {showLoader && <Loader />}
      <div className="flex justify-between">
        <div className="w-full max-h-full">
          <img src={LoginBG} className="w-dvw h-dvh" />
        </div>

        <div className="registrationBackground w-1/2 flex-col flex items-center justify-center bg-gray-100 w-full">
          <h1 className="text-4xl">Update Admin</h1>

          <div className="block my-5">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Frist Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3  border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="first_name"
              defaultValue={adminDetail?.first_name}
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.first_name && (
              <p className="text-xs text-red-500">{errors.first_name}</p>
            )}
          </div>
          <label className="block my-5">
            <span className="block text-sm font-medium text-slate-700 my-2.5">
              Middle Name:
            </span>
            <input
              type="text"
              className="min-w-96 p-3  border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="middle_name"
              defaultValue={userDetail.middle_name}
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.middle_name && (
              <p className="text-xs text-red-500">{errors.middle_name}</p>
            )}
          </label>
          <div className="block my-5">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Last Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3  border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="last_name"
              defaultValue={userDetail.last_name}
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.last_name && (
              <p className="text-xs text-red-500">{errors.last_name}</p>
            )}
          </div>

          <div className="block my-5">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Email:
            </p>
            <input
              type="email"
              className="min-w-96 p-3  border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="email"
              defaultValue={userDetail.email}
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.email && (
              <p className="text-xs text-red-500">{errors.email}</p>
            )}
          </div>

          <div className="block my-5">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Phone Number:
            </p>
            <input
              type="number"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="mobile_number"
              defaultValue={userDetail.mobile_number}
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.mobile_number && (
              <p className="text-xs text-red-500">{errors.mobile_number}</p>
            )}
          </div>

          <div className="flex flex-col justify-cenetr items-center mt-5">
            <button
              type="submit"
              className="rounded-lg bg-orange-500 p-2 text-white"
              onClick={FormSubmit}
            >
              Update Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateAdmin;
