import api from "./api";
import Cookies from "js-cookie";
export const httpsRequest = async (apiUrl, method, body, dataType) => {
  let token = localStorage.getItem("token") || sessionStorage.getItem("token") || Cookies.get("authToken") || "";
  let baseUrl = process.env.React_App_Base_Url;
     console.log(body,"@@@@@@@@@@@@");
     
  try {
    const config = {
      method: method.toUpperCase(),
      url: `${baseUrl}/${apiUrl}`,
      maxBodyLength: Infinity,
      headers: { Authorization: `Bearer ${token}` },
    };

    if (method.toUpperCase() === "DELETE" && body) {
      config.data = body;
    }

    if (method.toUpperCase() !== "DELETE" && body) {
      if (dataType === "json") {
        config.headers["Content-Type"] = "application/json";
        config.data = JSON.stringify(body);
      } else if (dataType === "formData") {
        config.data = body;
      } else {
        config.headers["Content-Type"] = "application/json";
        config.data = JSON.stringify(body);
      }
    }

    const res = await api.request(config);
    return res;
  } catch (err) {
    return err;
  }
};