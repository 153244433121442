import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";

function CropValuationCertificate() {
  const location = useLocation();
  const { state: formDetail } = location || {};
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [reportCheck, setReportCheck] = useState({
    agri_expert_check: false,
    agri_expert_verified: false,
    agri_expert_comment: "",
  });

  const handelInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log("value of radio buttons", name, Boolean(value));
    setReportCheck({
      ...reportCheck,
      [name]: type == "radio" ? Boolean(value) : value,
    });
  };
  const FormSubmit = async () => {
    try {
      setShowLoader(true);
      const response = await httpsRequest(
        `district-bdo/check-crops-valuation/${formDetail.farmer_service_id}`,
        "put",
        reportCheck
      );
      if (response.status == 200) {
        setShowLoader(false);
        navigate("/notification");
      }
    } catch (error) {
      setShowLoader(false);
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      <div className="py-5 px-5 lg:px-10">
        <p className="font-medium text-xl border-b-[1px] my-4 py-2">
          Crop Valuation Certificate
        </p>
        <p className="text-2xl lg:text-3xl font-medium mt-10 lg:mt-16 mb-2">
          Field Information:
        </p>

        <div className="grid grid-cols-1 gap-5 border-y-2 my-4 py-2">
          <div className="flex flex-col md:flex-row gap-5">
            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Farmer ID
              </p>
              <input
                type="text"
                name="farmer_id"
                disabled
                value={formDetail.farmer_id}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>

            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Aadhar Card
              </p>
              <input
                type="text"
                name="aadhaar_card"
                disabled
                value={formDetail.aadhaar_number}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-5">
            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Soil Testing %
              </p>
              <input
                type="text"
                name="soil_testing"
                disabled
                value={formDetail.soil_testing}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>

            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Farmer Valuation Card
              </p>
              <input
                type="text"
                name="farmer_valuation_card"
                disabled
                value={formDetail.farmer_evaluation_pct}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
          </div>
        </div>

        <p className="text-2xl lg:text-3xl font-medium mt-10 lg:mt-16 mb-2">
          Crop Yearly Details:
        </p>
        {formDetail?.crops_yearly_details?.map((crops, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-3 gap-5 border-y-2 my-4 py-2"
          >
            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Crops Name
              </p>
              <input
                type="text"
                name="crops_name"
                disabled
                value={crops.crop_name}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>

            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Crops Type
              </p>
              <input
                type="text"
                name="crops_type"
                disabled
                value={crops.crop_type}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Crops Duration
              </p>
              <input
                type="text"
                name="crops_duration"
                disabled
                value={crops.crop_duration}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Years
              </p>
              <input
                type="text"
                name="years"
                disabled
                value={crops.year}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Crops Production
              </p>
              <input
                type="text"
                name="crops_production"
                disabled
                value={crops.crop_production}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>

            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Crops Rate
              </p>
              <input
                type="text"
                name="crops_rate"
                disabled
                value={crops.crops_rate}
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
          </div>
        ))}

        <p className="text-2xl lg:text-3xl font-medium mt-10 lg:mt-16 mb-2">
          Agriculture Expert:
        </p>
        <div className="border-y-2 my-4 py-2">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-start mb-4 gap-8 md:gap-24">
            <div className="flex items-center mt-2">
              <label className="block text-sm font-medium text-slate-700 my-2.5 mr-4">
                Expert Check
              </label>
              <div className="flex items-center space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="agri_expert_check"
                    value="yes"
                    onChange={handelInputChange}
                    className="form-radio h-4 w-4 text-[#FF5E14] border-gray-300 focus:ring-[#FF5E14]"
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="agri_expert_check"
                    value=""
                    onChange={handelInputChange}
                    className="form-radio h-4 w-4 text-[#FF5E14] border-gray-300 focus:ring-[#FF5E14]"
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>

            <div className="flex items-center mt-4 md:mt-0">
              <label className="mr-4 block text-sm font-medium text-slate-700 my-2.5">
                Expert Verified
              </label>
              <div className="flex items-center space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="agri_expert_verified"
                    value="yes"
                    onChange={handelInputChange}
                    className="form-radio h-4 w-4 text-[#FF5E14] border-gray-300 focus:ring-[#FF5E14]"
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="agri_expert_verified"
                    value=""
                    onChange={handelInputChange}
                    className="form-radio h-4 w-4 text-[#FF5E14] border-gray-300 focus:ring-[#FF5E14]"
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="expertComment"
              className="block text-sm font-medium text-slate-700 my-2.5"
            >
              Expert Comment
            </label>
            <textarea
              id="expertComment"
              name="agri_expert_comments"
              value={formDetail.agri_expert_comments}
              onChange={(e) => handelInputChange(e)}
              rows="4"
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] rounded-lg"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-5 mt-10">
          <button
            type="button"
            // onClick={() => navigate("/dashboard")}
            className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={FormSubmit}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
    </>
  );
}

export default CropValuationCertificate;
