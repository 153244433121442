import React, { useEffect, useState } from "react";
import GraphIcon from "../../Images/GraphIcon.svg";
import { httpsRequest } from "../../Features/Auth/config";

function StateBDO() {
  const [noOfUser, setNoOfUser] = useState([]);
  const stateInformation = [
    { fieldName: "Total District B.D.O", value: "500" },
    { fieldName: "Total Farmer", value: "6500" },
    { fieldName: "Total Village Cordinator", value: "800" },
    { fieldName: "Farm Area in Bigha", value: "800" },
  ];

  useEffect(() => {
    GetAllUser();
  }, []);

  const GetAllUser = async () => {
    try {
      const response = await httpsRequest("state-bdo/get-total-user-on-state-bdo", "get");
      if (response.status === 200) {
        setNoOfUser(response.data);
      }
    } catch (error) {}
  };
  return (
    <>
        <div className="flex flex-col w-full py-5 ps-5 pe-10">
          <div className="grid grid-cols-4 gap-5">
            {noOfUser.map((detail) => (
              <>
                <div className="border-2 border-[#75A651] flex justify-between py-4 px-3 rounded-xl">
                  <div className="">
                    <p className="text-5xl">{detail.noOfUser}</p>
                    <p className="mt-2.5 text-base">{detail.userRole}</p>
                  </div>
                  <img src={GraphIcon} className="max-w-16 max-h-16" />
                </div>
              </>
            ))}
          </div>
        </div>
    </>
  );
}

export default StateBDO;
