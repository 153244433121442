import React, { useEffect, useState } from "react";
import DataTable from "../DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";

function AdminList() {
  const navigate = useNavigate();
  const [adminList, setAdminList] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  const tableHeaders = [
    "User status",
    "Admin Name",
    "Gmail",
    "Contact",
    "Action",
  ];
  const options = ["Edit", "Delete"];

  useEffect(() => {
    GetAdmin();
  }, []);
  const GetAdmin = async () => {
    try {
      setShowLoader(true)
      const response = await httpsRequest("v1/sa/admin", "get");
      if (response.status == 200) {
        setShowLoader(false)
        setAdminList(response?.data);
      } else {
        setShowLoader(false)
        console.log("Error Ocurred", response?.data);
      }
    } catch (error) {
      setShowLoader(false)
      console.error("didn't get the list of Admin");
    }
  };
  const DeleteAdmin = async (adminDetail) => {
    try {
      setShowLoader(true)
      const response = await httpsRequest(
        `v1/sa/admin/${adminDetail.admin_id}`,
        "delete"
      );
      if (response.status == 200) {
        setShowLoader(false)
        alert(`${adminDetail.first_name} is Deleted`);
        navigate("/dashboard");
      }
    } catch (error) {
      setShowLoader(false)
      console.error("Error deleting Item", error);
    }
  };

  return (
    <>
    {showLoader && <Loader/>}
      <div className="table border-2 rounded-xl border-[#DAE1E9] mt-7 w-full p-3 sm:p-4 md:p-3">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <h1 className="text-xl sm:text-2xl">Admin</h1>
          <button
            className="rounded-lg bg-orange-500 py-1.5 sm:py-2 px-2 sm:px-3 text-sm sm:text-base text-white mt-3 sm:mt-0"
            onClick={() => navigate("/admin-onboard")}
          >
            Add New Admin
          </button>
        </div>
        <DataTable
          options={options}
          data={adminList}
          headerOption={tableHeaders}
          DeleteUser={DeleteAdmin}
          itemsPerPage={10}
          action={true}
        />
      </div>
    </>
  );
}

export default AdminList;
