import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";

function EditFarm() {
  const location = useLocation();
  const { state: farmValue } = location || {};
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState({});
  const [farmDetail, setFarmDetail] = useState(farmValue);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (hasError) {
      toast.error(errors, {
        position: "top-right",
        autoClose: 8000, // 8 seconds
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [hasError]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "irrigation_status") {
      setFarmDetail({ ...farmDetail, [name]: Boolean(value )});
    } else {
      setFarmDetail({ ...farmDetail, [name]: value });
    }
  };

  const FormSubmit = async () => {
    try {
      setShowLoader(true);
      const response = await httpsRequest(
        `v3/village-coordinator/farm/${farmValue.farm_id}`,
        "put",
        farmDetail
      );
      if (response.status == 200) {
        setShowLoader(false);
        navigate("/dashboard");
      }
    } catch (error) {
      setShowLoader(false);
      console.log("getting errors", error);
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      <div className="py-5 ps-5 pe-10 w-full h-max">
        <ToastContainer />

        <p className="font-medium text-xl">Farm Information</p>
        <p className="text-3xl font-medium mt-16 mb-2">Farm Details</p>

        <div className="grid grid-cols-3 gap-5 border-y-2 my-4 py-2">
          <div className="w-full">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Khasra Number:
            </p>
            <input
              type="text"
              name="khasra_number"
              onChange={(e) => handleInputChange(e)}
              defaultValue={farmValue.khasra_number}
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            />
          </div>

          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Farm Size:
            </p>
            <input
              type="text"
              name="area"
              onChange={(e) => handleInputChange(e)}
              defaultValue={farmValue.area}
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            />
          </div>

          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Soil Type:
            </p>
            <select
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              onChange={(e) => handleInputChange(e)}
              name="soil_type"
            >
              <option value="">{farmValue.soil_type}</option>
              <option value="red">Red</option>
              <option value="black">Black</option>
            </select>
          </div>

          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Irrigation Status:
            </p>
            <select
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              onChange={(e) => handleInputChange(e)}
              name="irrigation_status"
            >
              <option value="">
                {farmValue.irrigation_status == true ? "Yes" : "No"}
              </option>
              <option value="true">Yes</option>
              <option value="">No</option>
            </select>
          </div>

          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Water Resource:
            </p>
            <input
              type="text"
              name="water_resource"
              onChange={(e) => handleInputChange(e)}
              defaultValue={farmValue.water_resource}
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            />
          </div>
        </div>

        <div className="flex justify-center gap-5 mt-5">
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={FormSubmit}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default EditFarm;
