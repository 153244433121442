// src/components/DropdownButton.js
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
const Base_Url = process.env.React_App_Base_Url;
const DropdownButton = ({ userDetail, options,DeleteUser,SelectedOption,icon }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className="relative inline-block text-left cursor-pointer" onClick={handleMouseEnter}>
      {icon ? icon : <BsThreeDots />}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
          <div className="py-1">
            {options?.map((option, index) => (
              <a
                onClick={(e) => SelectedOption(option,userDetail)}
                key={index}
                href={option.href}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
