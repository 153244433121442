import React, { useState } from "react";
import Logo from "../../Images/Logo.svg";
import MessageIcon from "../../Images/MessageIcon.svg";
import NotificationIcon from "../../Images/NotificationIcon.svg";
import DropdownButton from "../DropdownButton/DropdownButton";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

function Navbar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const options = ["My Profile", "Setting"];
  const navigation = useNavigate();
  const userJsonValue = localStorage.getItem("userValue");
  const userValue = userJsonValue ? JSON.parse(userJsonValue) : null;
  const SelectedOption = (option) => {
    setIsOpen(!isOpen);
    if (option == "My Profile") {
      navigate("/user-profile");
    }
  };
  const navigateNotification = () => {
    navigate("/notification");
  };
  return (
    <>
      <div className="flex justify-between py-2 px-12 bg-[#EBEFF2] sticky top-0 z-50 shadow-sl">
        {/* <div className='flex justify-between'> */}
        <img src={Logo} />

        {/* </div> */}
        <div className="flex gap-3 items-center">
          <img src={MessageIcon} alt="message" className="max-w-6" />
          <div onClick={() => navigateNotification()}>
            <img
              src={NotificationIcon}
              alt="notification"
              className="max-w-6"
            />
          </div>

          <div>
            <p>{userValue.first_name ? userValue.first_name : "hello"}</p>
            <p>{userValue.last_name ? userValue.last_name : "user"}</p>
          </div>

          <DropdownButton
            options={options}
            SelectedOption={SelectedOption}
            icon={<FaChevronDown />}
          />
        </div>
      </div>
    </>
  );
}

export default Navbar;
