import React, { useState } from "react";
import Loader from "../Loader/Loader";

function AgriculturePlanning() {
  const [showLoader, setShowLoader] = useState(false)

  const FormSubmit = () => {
    setShowLoader(true)
  }
  return (
    <>
    {showLoader && <Loader/>}
      <div className="py-2 ps-5 pe-10">
        <p className="font-medium text-xl border-b-[1px] py-2">
          Agriculture Planning
        </p>
        <p className="text-xl font-medium mt-11 mb-2">Field Information:</p>
      </div>

      <div className="grid grid-cols-3 gap-y-5 ps-5 pe-10 mt-3">
        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Farmer Id
          </p>
          <input
            type="text"
            name="farmer_id"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Aadhar Number
          </p>
          <input
            type="text"
            name="aadhar_number"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        {/* <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Email
          </p>
          <input
            type="text"
            name="email"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div> */}

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Land Size
          </p>
          <input
            type="text"
            name="land_size"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Crops Name
          </p>
          <input
            type="text"
            name="crop_name"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Water Availibility
          </p>
          <input
            type="text"
            name="water_availibility"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Soil Type
          </p>
          <input
            type="text"
            name="soil_type"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
           Soil Health Report
          </p>
          <input
            type="text"
            name="soil_report"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Fertilizer Used
          </p>
          <input
            type="text"
            name="fertilizer_used"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        {/* <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Market Distance
          </p>
          <input
            type="text"
            name="market_distance"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div>

        <div className="sm:w-full md:max-w-96">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Pricing Strategy
          </p>
          <input
            type="text"
            name="pricing_strategy"
            // onChange={(e) => handelInputChange(e)}
            className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
          />
        </div> */}
      </div>

      <div className="flex justify-center gap-5 mt-12">
        <button
          type="button"
        //   onClick={() => navigate("/dashboard")}
          className="rounded-lg bg-[#6E7C87] p-2 px-7 text-white"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={FormSubmit}
          className="rounded-lg bg-[#0391CF] p-2 px-7 text-white"
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default AgriculturePlanning;
