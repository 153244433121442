import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Base_Url = process.env.React_App_Base_Url;

function PopUp({ closeModal, receiptId }) {
  const navigate = useNavigate()
  const handelDownloadReport = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/v3/village-coordinator/service/farmer_valuation_pdf/${receiptId}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });

      const link = document.createElement("a");

      const url = window.URL.createObjectURL(blob);

      link.href = url;

      link.setAttribute("download", "filename.pdf");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      // navigate('/services')
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  const handelDownloadBill = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/v3/village-coordinator/service/service_charge_receipt_pdf/${receiptId}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });

      const link = document.createElement("a");

      const url = window.URL.createObjectURL(blob);

      link.href = url;

      link.setAttribute("download", "filename.pdf");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      // navigate('/services')
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <div
      id="modal-background"
      className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50"
    >
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
        <div className="flex justify-between items-center border-b border-gray-200 p-4">
          <h2 className="text-lg font-semibold">Farm Valuation Certificate</h2>
          <button
            className="text-gray-400 hover:text-gray-600"
            onClick={closeModal}
          >
            &times;
          </button>
        </div>

        <div className="p-4">
          <p>Do you want Receipt of Valution card</p>
        </div>

        <div className="flex justify-end gap-5 border-t border-gray-200 p-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={handelDownloadReport}
          >
            DownLoad Report
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={handelDownloadBill}
          >
            Download Bill
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
