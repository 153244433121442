import React, { useEffect, useState } from "react";
import DropdownButton from "../DropdownButton/DropdownButton";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

function DataTable(componentData) {
  const navigate = useNavigate();
  const { data, headerOption, action, itemsPerPage, DeleteUser, options } =
    componentData;
  const [itemOffset, setItemOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const pageCount = Math.ceil(data?.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data?.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const SelectedOption = (option, userDetail) => {
    setIsOpen(!isOpen);

    if (option === "Edit") {
      navigate("/update-admin", { state: userDetail });
    } else if (option == "Delete") {
      DeleteUser(userDetail);
    } else {
      console.log("option=======>>>>", option);
    }
  };

  return (
    <>
      <table className="table border-2 border-[#DAE1E9] mt-5 w-full text-xs sm:text-sm md:text-base lg:text-base">
        <thead>
          <tr>
            {headerOption?.map((options) => (
              <th
                scope="col"
                className="px-1 py-1 sm:px-2 md:px-2 lg:px-4 py-1 sm:py-2 md:py-3 lg:py-4 text-[#383737] capitalize tracking-wider text-left sm:text-center"
                key={options}
              >
                {options}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {currentItems &&
            currentItems.map((value, index) => (
              <tr
                key={index}
                className="border-2 border-[#DAE1E9] text-left sm:text-center min-h-10 sm:min-h-12 md:min-h-14 lg:min-h-16"
              >
                <td className="px-1 py-1 sm:px-2 md:px-2 lg:px-4 text-xs sm:text-sm md:text-base lg:text-base font-medium text-[#383737] capitalize tracking-wider">
                  {value?.is_active ? (
                    <span className="text-xs sm:text-sm md:text-sm lg:text-base text-[#FF9933]">
                      Active
                    </span>
                  ) : (
                    <span className="text-xs sm:text-sm md:text-sm lg:text-base text-[#FF9933]">
                      Not Active
                    </span>
                  )}
                </td>
                <td className="px-1 py-1 sm:px-2 md:px-2 lg:px-4 text-xs sm:text-sm md:text-sm lg:text-base font-medium text-[#383737] capitalize tracking-wider">
                  {value?.first_name}
                </td>
                <td className="px-1 py-1 sm:px-2 md:px-2 lg:px-4 text-xs sm:text-sm md:text-sm lg:text-base font-medium text-[#383737] capitalize tracking-wider">
                  {value?.email}
                </td>
                <td className="px-1 py-1 sm:px-2 md:px-2 lg:px-4 text-xs sm:text-sm md:text-sm lg:text-base font-medium text-[#383737] capitalize tracking-wider">
                  {value?.mobile_number}
                </td>
                {action && (
                  <td className="px-1 py-1 sm:px-2 md:px-2 lg:px-4">
                    <DropdownButton
                      options={options}
                      userDetail={value}
                      DeleteUser={DeleteUser}
                      SelectedOption={SelectedOption}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      {itemsPerPage < 10 ? (
        ""
      ) : (
        <ReactPaginate
          className="flex gap-2 md:gap-5 justify-center md:justify-end mt-2"
          activeClassName="text-blue-500"
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
        />
      )}
    </>
  );
}

export default DataTable;
