import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";
const Base_Url = process.env.React_App_Base_Url;

function AddUser() {
  const location = useLocation();
  const { state: role } = location || {};
  const token = sessionStorage.getItem("token");
  const [showLoader, setShowLoader] = useState(false)
  const [userDetail, setUserDetail] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    aadhar_number: "",
    pan_number: "",
    state_id: null,
    state_name: null,
    district_id: null,
    district_name: null,
    village_id: null,
    village_name: null,
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    aadhar_number: "",
    pan_number: "",
    state_id: null,
    state_name: null,
  });
  const [stateList, setStateList] = useState([]);
  const [distrcitList, setDistrictList] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetState();
  }, []);
  const GetState = async () => {
    try {
      setShowLoader(true)
      const response = await httpsRequest("v4/states", "get");
      // const response = await axios.get(`${Base_Url}/v4/states`);
      console.log("response from state",response.data)
      if (response.status == 200) {
        setShowLoader(false)
        setStateList(response?.data);
        console.log("response===>>>", response.data);
      }
    } catch (error) {
      setShowLoader(false)
      console.error(error);
    }
  };
  const GetDistrict = async (stateId) => {
    const id = stateId.slice(0, 4);
    try {
      setShowLoader(true)
      const response = await httpsRequest(`v4/districts/${id}`, "get")
      // const response = await axios.get(`${Base_Url}/v4/districts/${id}`);

      if (response.status == 200) {
        setShowLoader(false)
        setDistrictList(response?.data);
      }
    } catch (error) {
      setShowLoader(false)
      console.error(error);
    }
  };
  const GetVillage = async (districtId) => {
    const id = districtId.slice(0, 12);

    try {
      setShowLoader(true)
      const response = await httpsRequest(`v4/villages/${id}`, "get")
      if (response.status == 200) {
        setShowLoader(false)
        setVillageList(response.data);
      }
    } catch (error) {
      setShowLoader(false)
      console.error(error);
    }
  };

  const FormDetail = (e) => {
    const { value, name } = e.target;
    if (name == "state") {
      const id = value.slice(0, 4);
      const name = value.slice(5);
      setUserDetail({ ...userDetail, state_id: Number(id), state_name: name });
    } else if (name == "district") {
      const id = value.slice(0, 12);
      const name = value.slice(13);
      setUserDetail({
        ...userDetail,
        district_id: Number(id),
        district_name: name,
      });
    } else if (name == "village") {
      const id = value.slice(0, 12);
      const name = value.slice(13);
      setUserDetail({
        ...userDetail,
        village_id: Number(id),
        village_name: name,
      });
    } else {
      setUserDetail({ ...userDetail, [name]: value });
    }
    if (value == "") {
      if (name == "district" || name == "village") {
        setFormErrors({ ...formErrors });
      } else {
        setFormErrors({ ...formErrors, [name]: "Value is required" });
      }
    } else {
      const { [name]: removedError, ...restErrors } = formErrors;
      setFormErrors(restErrors);
    }
  };

  const FormSubmit = async () => {
    const errors = {};
    Object.keys(formErrors).forEach((key) => {
      if (!userDetail[key]) {
        errors[key] = `${key} is required`;
      }
    });
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      try {
        setShowLoader(true)
        const response = await axios.post(`${Base_Url}/v2/admin/user`, {
          first_name: userDetail.first_name,
          middle_name: userDetail.middle_name,
          last_name: userDetail.last_name,
          email: userDetail.email,
          mobile_number: Number(userDetail.mobile_number),
          role: Number(role),
          aadhaar_number: Number(userDetail.aadhar_number),
          pan_number: userDetail.pan_number,
          state_id: Number(userDetail.state_id),
          state_name: userDetail.state_name,
          district_id: Number(userDetail.district_id),
          district_name: userDetail.district_name,
          village_id: Number(userDetail.village_id),
          village_name: userDetail.village_name,
        },{ headers: { Authorization: `Bearer ${token}` } });
        if (response.status == 201) {
          setShowLoader(false)
          navigate("/dashboard");
        }
      } catch (error) {
        setShowLoader(false)
        console.error("error===>>>", error);
      }
    }
  };

  return (
    <>
    {showLoader && <Loader/>}
      <div className="p-5 w-full">
        <p className="text-3xl font-medium">
          Add New{" "}
          {role == 3
            ? "State BDO"
            : role == 4
            ? "District BDO"
            : "Village Coordinator"}
        </p>
        <div className="grid grid-cols-3 gap-y-5 gap-x-10 mt-5">
          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Frist Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="first_name"
              value={userDetail.first_name}
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.first_name && (
              <p className="text-xs text-red-500">{formErrors.first_name}</p>
            )}
          </div>
          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Middle Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="middle_name"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.middle_name && (
              <p className="text-xs text-red-500">{formErrors.middle_name}</p>
            )}
          </div>
          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Last Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="last_name"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.last_name && (
              <p className="text-xs text-red-500">{formErrors.last_name}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Email:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="email"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.email && (
              <p className="text-xs text-red-500">{formErrors.email}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Mobile Number:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="mobile_number"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.mobile_number && (
              <p className="text-xs text-red-500">{formErrors.mobile_number}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Aadhar Number:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="aadhar_number"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.aadhar_number && (
              <p className="text-xs text-red-500">{formErrors.aadhar_number}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Pan Number:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="pan_number"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.pan_number && (
              <p className="text-xs text-red-500">{formErrors.pan_number}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              State Name:
            </p>
            <select
              onChange={(e) => (FormDetail(e), GetDistrict(e.target.value))}
              name="state"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            >
              {stateList?.map((state) => (
                <option
                  key={state.id}
                  value={[state.id, state.name]}
                  data-id={state.id}
                >
                  {state.name}
                </option>
              ))}
            </select>
            {!userDetail.state_id && (
              <p className="text-xs text-red-500">{formErrors.state_id}</p>
            )}
          </div>
          {(role == 4 || role == 5) && (
            <div className="block">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                District Name:
              </p>
              <select
                name="district"
                onChange={(e) => (FormDetail(e), GetVillage(e.target.value))}
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              >
                {distrcitList?.map((district) => (
                  <option value={[district.id, district.name]}>
                    {district.name}
                  </option>
                ))}
              </select>
              {!userDetail.district_id && (
                <p className="text-xs text-red-500">{formErrors.district_id}</p>
              )}
            </div>
          )}
          {role == 5 && (
            <div className="block">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Village Name:
              </p>
              <select
                name="village"
                onChange={(e) => FormDetail(e)}
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              >
                {villageList?.map((village) => (
                  <option value={[village.id, village.name]}>
                    {village.name}
                  </option>
                ))}
              </select>
              {!userDetail.village_id && (
                <p className="text-xs text-red-500">{formErrors.village_id}</p>
              )}
            </div>
          )}
        </div>

        <div className="flex justify-center gap-5 mt-5">
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => FormSubmit()}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default AddUser;
