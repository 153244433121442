import React from "react";
import SideBar from "../SideBar/SideBar";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

function Layout({ children }) {
  const location = useLocation();
  const authRoutes = [
    "/welcome",
    "/login",
    "/auth/create-password",
    "/update-admin",
    "/admin-onboard",
  ];
  const isAuthRoutes = authRoutes.includes(location.pathname);
  return (
    <div className="">
      {!isAuthRoutes && <Navbar/>}
      <div className="flex">
        {!isAuthRoutes && <SideBar/>}
        <main className="w-full h-screen overflow-y-scroll p-5 md:p-3">
          <Outlet/>
        </main>
      </div>
    </div>
  );
}

export default Layout;
