import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import PopUp from "../PopUp/PopUp";
import Loader from "../Loader/Loader";

function CropValuation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { service_id } = location.state || {};
  const [receiptPop, setReceiptPop] = useState(false);
  const [receiptId, setReceiptId] = useState();
  const [formData, setFormData] = useState({
    farmer_id: "",
    aadhaar_number: "",
    service_id: "",
    crops_yearly_details: [],
    soil_testing: "",
    farmer_evaluation_pct: "",
  });
  const [cropsDetail, setCropsDetail] = useState({
    crop_name: "",
    crop_type: "",
    crop_duration: "",
    year: "",
    crop_production: "",
    crops_rate: "",
  });
  const [showLoader, setShowLoader] = useState(false)

  const handelInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handelCropsDetail = (e) => {
    const { name, value } = e.target;
    setCropsDetail({ ...cropsDetail, [name]: value });
  };

  const cropsFormSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      crops_yearly_details: [...formData.crops_yearly_details, cropsDetail],
    });
    setCropsDetail({
      crop_name: "",
      crop_type: "",
      crop_duration: "",
      year: "",
      crop_production: "",
      crops_rate: "",
    });
  };

  const formSubmit = async () => {
    const updatedCropsYearlyDetails = [...formData.crops_yearly_details];
    if (Object.values(cropsDetail).some((val) => val !== "")) {
      updatedCropsYearlyDetails.push(cropsDetail);
    }
    const finalFormData = {
      ...formData,
      crops_yearly_details: updatedCropsYearlyDetails,
      service_id,
    };
    try {
      setShowLoader(true)
      const response = await httpsRequest(
        "v3/village-coordinator/service/crops_valuation",
        "post",
        finalFormData
      );
      if (response.status === 201) {
        setShowLoader(false)
        setReceiptPop(true);
        setReceiptId(response.data.farmer_service_id);
        // navigate("/services");
      }
    } catch (error) {
      setShowLoader(false)
    }
  };

  const closeModal = () => {
    setReceiptPop(false);
  };

  return (
    <>
    {showLoader && <Loader/>}
      {receiptPop && <PopUp receiptId={receiptId} closeModal={closeModal} />}
      <div className="py-5 ps-5 pe-10">
        <p className="font-medium text-xl border-b-[1px] my-4 py-2">
          Crop Valuation Certificate
        </p>
        <p className="text-2xl md:text-3xl font-medium mt-16 mb-2">
          Field Information:
        </p>

        <div className="grid grid-cols-1 gap-5 border-y-2 my-4 py-2">
          <div className="flex flex-col md:flex-row gap-5">
            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Farmer ID
              </p>
              <input
                type="text"
                name="farmer_id"
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>

            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Aadhar Card
              </p>
              <input
                type="text"
                name="aadhaar_card"
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-5">
            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Soil Testing %
              </p>
              <input
                type="text"
                name="soil_testing"
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>

            <div className="sm:w-full md:max-w-96">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Farmer Valuation Card
              </p>
              <input
                type="text"
                name="farmer_evaluation_pct"
                onChange={(e) => handelInputChange(e)}
                className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
              />
            </div>
          </div>
        </div>

        <p className="text-2xl md:text-3xl font-medium mt-16 mb-2">
          Crop Yearly Details:
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 border-y-2 my-4 py-2">
          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Crops Name
            </p>
            <input
              type="text"
              name="crop_name"
              value={cropsDetail.crop_name}
              onChange={(e) => handelCropsDetail(e)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>

          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Crops Type
            </p>
            <input
              type="text"
              name="crop_type"
              value={cropsDetail.crop_type}
              onChange={(e) => handelCropsDetail(e)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>
          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Crops Duration
            </p>
            <input
              type="text"
              name="crop_duration"
              value={cropsDetail.crop_duration}
              onChange={(e) => handelCropsDetail(e)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>
          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Year
            </p>
            <input
              type="text"
              name="year"
              value={cropsDetail.year}
              onChange={(e) => handelCropsDetail(e)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>
          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Crops Production
            </p>
            <input
              type="text"
              name="crop_production"
              value={cropsDetail.crop_production}
              onChange={(e) => handelCropsDetail(e)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>

          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Crops Rate
            </p>
            <input
              type="text"
              name="crops_rate"
              value={cropsDetail.crops_rate}
              onChange={(e) => handelCropsDetail(e)}
              className="w-full p-3 border-solid border-[1px] border-[#DAE1E9] h-12 rounded-lg"
            />
          </div>
        </div>

        <div className="flex justify-center gap-5 mt-10">
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={cropsFormSubmit}
            className="rounded-lg bg-[#0391CF] p-2 px-5 text-white"
          >
            Next
          </button>
          <button
            type="button"
            onClick={formSubmit}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default CropValuation;
