import React, { useState } from "react";
import LoginBG from "../../Images/RegistrationBG.jpg";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { httpsRequest } from "../../Features/Auth/config";

function AdminOnboarding() {
  const [userDetail, setUserDetail] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const filedsName = {
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    email: "Email",
    mobile_number: "Mobile Number",
  };
  const FormDetail = (e) => {
    const { name, value } = e.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  const FormSubmit = async () => {
    // if (userDetail.fName == "") {
    //   setErrors({ ...errors, fName: "First Name field can't be empty" });
    // } else if (userDetail.mName == "") {
    //   setErrors({ ...errors, mName: "Middle Name field can't be empty" });
    // } else if (userDetail.lName == "") {
    //   setErrors({ ...errors, lName: "Last Name field can't be empty" });
    // } else if (userDetail.email == "") {
    //   setErrors({ ...errors, email: "Email field can't be empty" });
    // } else if (userDetail.number == "") {
    //   setErrors({ ...errors, number: "Number field can't be empty" });
    // } else

    const formErrors = {};
    Object.keys(errors).forEach((key) => {
      if (!userDetail[key]) {
        const fieldName = filedsName[key] || key;
        formErrors[key] = `${fieldName} Can't be empty`;
      }
    });
    setErrors(formErrors);
    console.log("formErrors=======",formErrors)

    if (Object.keys(formErrors).length === 0) {
      try {
        setShowLoader(true);
        const formData = {
          first_name: userDetail.first_name,
          middle_name: userDetail.middle_name,
          last_name: userDetail.last_name,
          email: userDetail.email,
          mobile_number: Number(userDetail.mobile_number),
        };
        const response = await httpsRequest("v1/sa/admin", "post", formData);
        if (response.status == 200) {
          setShowLoader(false);
          navigate("/dashboard");
        }else{
          setShowLoader(false)
        }
      } catch (error) {
        setShowLoader(false);
        console.error("Sign In failed:", error);
        setErrorMessage("Add Admin failed please try again");
      }
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      <div className="flex flex-col md:flex-row justify-between">
        <div className="w-full md:w-1/2 max-h-full">
          <img src={LoginBG} className="w-full h-auto md:h-dvh" />
        </div>

        <div className="registrationBackground w-full md:w-1/2 flex-col flex items-center justify-center bg-gray-100 p-5 md:p-10">
          <h1 className="text-2xl md:text-4xl text-center">Admin OnBoarding</h1>

          <div className="block my-5">
            <p className="block text-sm md:text-base font-medium text-slate-700 my-2.5">
              First Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="first_name"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.first_name && (
              <p className="text-xs text-red-500">{errors.first_name}</p>
            )}
          </div>

          <div className="block my-5">
            <p className="block text-sm md:text-base font-medium text-slate-700 my-2.5">
              Middle Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="middle_name"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.middle_name && (
              <p className="text-xs text-red-500">{errors.middle_name}</p>
            )}
          </div>

          <div className="block my-5">
            <p className="block text-sm md:text-base font-medium text-slate-700 my-2.5">
              Last Name:
            </p>
            <input
              type="text"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="last_name"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.last_name && (
              <p className="text-xs text-red-500">{errors.last_name}</p>
            )}
          </div>

          <div className="block my-5">
            <p className="block text-sm md:text-base font-medium text-slate-700 my-2.5">
              Email:
            </p>
            <input
              type="email"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="email"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.email && (
              <p className="text-xs text-red-500">{errors.email}</p>
            )}
          </div>

          <div className="block my-5">
            <p className="block text-sm md:text-base font-medium text-slate-700 my-2.5">
              Phone Number:
            </p>
            <input
              type="number"
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="mobile_number"
              onChange={(e) => FormDetail(e)}
            />
            {!userDetail.mobile_number && (
              <p className="text-xs text-red-500">{errors.mobile_number}</p>
            )}
          </div>

          <div className="flex flex-col justify-center items-center mt-5">
            <button
              type="submit"
              className="rounded-lg bg-orange-500 p-2 md:p-3 text-white w-full md:w-auto"
              onClick={FormSubmit}
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminOnboarding;
