import React, { useEffect, useState } from "react";
import DataTable from "../DataTable/DataTable";
import { useLocation, useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";

function UserList() {
  const location = useLocation();
  const { selectUserType, userList } = location.state || {};
  const [updatedList, setUpdatedList] = useState(userList);
  const tableHeaders = [
    "User status",
    "User Name",
    "Gmail",
    "Contact",
    "Action",
  ];
  const actionOption = ["Edit", "Delete"];
  const [showLoader, setShowLoader] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    OnDataFilter(selectUserType);
  }, []);

  const OnDataFilter = (selectUserType) => {
    if (selectUserType == "state_bdo") {
      const filterData = userList.filter((value) => value.role == 3);
      setUpdatedList(filterData);
    } else if (selectUserType == "district_bdo") {
      const filterData = userList.filter((value) => value.role == 4);
      setUpdatedList(filterData);
    } else if (selectUserType == "village_coordinator") {
      const filterData = userList.filter((value) => value.role == 5);
      setUpdatedList(filterData);
    } else {
      setUpdatedList(userList);
    }
  };

  const DeleteUser = async (userDetail) => {
    try {
      setShowLoader(true)
      const response = await httpsRequest(
        `v2/admin/user/${userDetail.user_id}`,
        "delete"
      );
      if (response.status == 200) {
        setShowLoader(false)
        alert(`${userDetail.first_name} is Deleted`);
        navigate("/dashboard");
      }
    } catch (error) {
      setShowLoader(false)
      console.error("Error deleting Item", error);
    }
  };

  return (
    <>
    {showLoader && <Loader/>}
      <div className="border-2 rounded-xl border-[#DAE1E9] mt-12 mb-10 w-full p-5 max-h-[80vh] overflow-y-auto">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <h1 className="text-xl md:text-2xl mb-4 md:mb-0">
            {selectUserType == "state_bdo"
              ? "State BDO"
              : selectUserType == "district_bdo"
              ? "District BDO"
              : "Village Coordinator"}
          </h1>
          <button
            className="rounded-lg bg-orange-500 py-2 px-3 text-white"
            onClick={() =>
              navigate("/add-user", {
                state:
                  selectUserType == "state_bdo"
                    ? 3
                    : selectUserType == "district_bdo"
                    ? 4
                    : 5,
              })
            }
          >
            Add New User
          </button>
        </div>
        <div className="mt-4">
          <DataTable
            data={updatedList}
            headerOption={tableHeaders}
            action={true}
            itemsPerPage={10}
            DeleteUser={DeleteUser}
            options={actionOption}
          />
        </div>
      </div>
    </>
  );
}

export default UserList;
