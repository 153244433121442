import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DropdownButton from "../DropdownButton/DropdownButton";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";
const Base_Url = process.env.React_App_Base_Url;

function FarmList() {
  const location = useLocation();
  const { state: value } = location || {};
  const navigate = useNavigate();
  const [totalFarm, setTotalFarm] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [farmDelete, setFarmDelete] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const itemsPerPage = 10;
  const options = ["Edit", "Delete"];
  const header = [
    "Khasra Number",
    "Area",
    "Soil Type",
    "Irrigation",
    "Water Resource",
    "Action",
  ];
  const pageCount = Math.ceil(totalFarm?.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = totalFarm?.slice(itemOffset, endOffset);

  useEffect(() => {
    GetAllFarm();
  }, [farmDelete]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalFarm.length;
    setItemOffset(newOffset);
  };

  const GetAllFarm = async () => {
    try {
      setShowLoader(true);
      const response = await httpsRequest(
        `v3/village-coordinator/farm/${value.farmer_id}`,
        "get"
      );
      console.log("response==========",response)
      if (response.status == 200) {
        setShowLoader(false);
        setTotalFarm(response?.data);
      }else{
        setShowLoader(false)
      }
    } catch (error) {
      console.log("testing data from get all farm")
      setShowLoader(false);
    }
  };

  const SelectedOption = (option, farmValue) => {
    setIsOpen(!isOpen);
    if (option == "Edit") {
      navigate("/update-farm", { state: farmValue });
    } else if (option == "Delete") {
      DeleteUser(farmValue);
    } else {
      console.log("option=======>>>>", option);
    }
  };

  const DeleteUser = async (farmValue) => {
    try {
      setShowLoader(true)
      const response = await httpsRequest(
        `v3/village-coordinator/farm/${farmValue.farm_id}`,
        "delete"
      );
      if (response.status == 200) {
        setShowLoader(false)
        navigate("/dashboard");
        setFarmDelete(!farmDelete);
      }
    } catch (error) {
      setShowLoader(false)
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className="flex flex-col w-full py-5 ps-5 pe-10 height">
        <p className="font-medium text-xl">Farm History</p>
        <table className="table border-2 border-[#DAE1E9] mt-5 w-full ">
          <thead>
            <tr>
              {header.map((header) => (
                <th
                  scope="col"
                  className="px-6 py-3 text-[#383737] capitalize tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentItems &&
              currentItems.map((farm) => (
                <tr className="border-2 border-[#DAE1E9] text-center min-h-14">
                  <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                    {farm.khasra_number}
                  </td>
                  <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                    {farm.area}
                  </td>
                  <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                    {farm.soil_type}
                  </td>
                  <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                    {farm.irrigation_status == true ? "Yes" : "No"}
                  </td>
                  <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                    {farm.water_resource
                      ? farm.water_resource
                      : "Not Availabel"}
                  </td>
                  <td>
                    <DropdownButton
                      options={options}
                      userDetail={farm}
                      DeleteUser={DeleteUser}
                      SelectedOption={SelectedOption}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <ReactPaginate
          className="flex gap-5 justify-end mt-2"
          activeClassName="text-blue-500"
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default FarmList;
