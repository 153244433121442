import React from "react";

const UploadComponent = ({ title, name, onFileSelect }) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.name;
    onFileSelect(fileName, file);
  };
  return (
    <div className="flex flex-col text-center">
      <label htmlFor={name} className="upload-icon">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm9 5a1 1 0 10-2 0v3.586l-.293-.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L13 11.586V8z" clipRule="evenodd" />
        </svg>
      </label>

      <input
        id={name}
        type="file"
        name={name}
        accept="imagge/*"
        className="file-input"
        onChange={(e) => handleFileChange(e)}
      />
      
      <span className="mt-2 text-base ">{title}</span>
    </div>
  );
};

export default UploadComponent;
