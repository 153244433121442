import React, { useState } from "react";
import LoginBG from "../../Images/RegistrationBG.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { httpsRequest } from "../../Features/Auth/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";
import Eye from "../../Images/Eye.svg";
import EyeClose from "../../Images/EyeClose.svg";

const Base_Url = process.env.React_App_Base_Url;
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function CreatePassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const query = useQuery();
  const email = query.get("email");
  const role = query.get("role");
  const token = query.get("token");

  const fieldNames = {
    password: "Password",
    confirmPassword: "Confirm Password"
  }

  const PasswordDetail = (e) => {
    const { name, value } = e.target;

    setPassword({ ...password, [name]: value });
  };

  const PasswordChange = async () => {
    try {
      const queryParams = {
        email: email,
        role: Number(role),
        token: token,
      };
      setShowLoader(true);
      const formErrors = {};

    Object.keys(errors).forEach((key) => {
      if (!password[key]) {
        const fieldName = fieldNames[key] || key;
        formErrors[key] = `${fieldName} Can't be empty`;
      }
    });
    setErrors(formErrors);
      // const response = await httpsRequest("auth/create-password", "post", formData,{ params: queryParams });
      const response = await axios.post(
        `${Base_Url}/auth/create-password/`,
        password,
        { params: queryParams }
      );
      if (response.status == 200) {
        setShowLoader(false);
        navigate("/login");
      }
    } catch (error) {
      setShowLoader(false);
      console.error("Unable to Change password Please check field", error);
    }
  };

  const ResendMail = async () => {
    setShowLoader(true);
    try {
      const formData = {
        email: email,
        role: Number(role),
      };
      setShowLoader(true);
      const response = await httpsRequest(
        "auth/resend-password-link",
        "post",
        formData
      );
      if (response.status === 200) {
        setShowLoader(false);
        toast.success("New link is sent to your email", {
          position: "top-right",
          autoClose: 3000, // 3 seconds duration
        });
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Failed to send link, Try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <ToastContainer />
      <div className="flex justify-between">
        <div className="w-full max-h-full">
          <img src={LoginBG} className="w-dvw h-dvh" />
        </div>

        <div className="registrationBackground w-1/2 flex-col flex items-center justify-center bg-gray-100 w-full">
          <h1 className="text-3xl font-semibold">Create Password</h1>
          <div className="block my-5">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Password:
            </p>
            <div className="relative">
              <input
                type={showPassword ? "password" : "text"}
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                name="password"
                onChange={(e) => PasswordDetail(e)}
              />
              {showPassword ? (
                <img
                  onClick={() => setShowPassword(false)}
                  src={Eye}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              ) : (
                <img
                  onClick={() => setShowPassword(true)}
                  src={EyeClose}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="block my-5">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Confirm Password:
            </p>
            <div className="relative">
              <input
                type={showConfirmPassword ? "password" : "text"}
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                name="confirmPassword"
                onChange={(e) => PasswordDetail(e)}
              />
              {showConfirmPassword ? (
                <img
                  onClick={() => setShowConfirmPassword(false)}
                  src={Eye}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              ) : (
                <img
                  onClick={() => setShowConfirmPassword(true)}
                  src={EyeClose}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              )}
            </div>
          </div>
          <button
            type="submit"
            className="rounded-lg bg-orange-500 py-2 px-3 text-white"
            onClick={PasswordChange}
          >
            Submit
          </button>
          <button
            type="submit"
            className="rounded-lg bg-orange-500 py-2 px-3 text-white mt-5"
            onClick={ResendMail}
          >
            Resend
          </button>
        </div>
      </div>
    </>
  );
}

export default CreatePassword;
