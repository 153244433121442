import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../../Images/DashboardIcon.svg";
import AddAdmin from "../../Images/AddAdmin.svg";
import UserProfile from "../../Images/UserProfile.svg";
import Services from "../../Images/services.svg";
import Logout from "../../Images/Logout.svg";
import ActiveDashboardIcon from "../../Images/ActiveDashboardIcon.svg";
import ActiveUserProfile from "../../Images/ActiveUserProfile.svg";
import ActiveServices from "../../Images/ActiveServices.svg";
import Cookies from "js-cookie";
function SideBar() {
  const userValue = localStorage.getItem("userValue");
  const userInfo = JSON.parse(userValue);
  const [activeOption, setActiveOption] = useState("DashBoard");

  const MenuOption = [
    { name: "DashBoard", logo: DashboardIcon, activeLogo:ActiveDashboardIcon },
    // { name: "Add Admin", logo: AddAdmin },
    { name: "User Profile", logo: UserProfile,activeLogo: ActiveUserProfile},
    { name: "All Services", logo: Services,activeLogo: ActiveServices},
  ];
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    Cookies.remove("authToken");
    sessionStorage.removeItem("token");
    navigate("/");
  };
  const SideBarMenu = (value) => {
    console.log("value-=-=-=-=-=",value)
    setActiveOption(value)
    if (value == "DashBoard") {
      navigate("/dashboard");
    } else if (value == "User Profile") {
      navigate("/update-profile");
    } else if (value == "All Services") {
      navigate("/services");
    } else {
      console.log("waiting for other option");
    }
  };
  return (
    <>
      <div className="flex w-full sm:w-1/6 md:w-1/6 h-screen bg-[#E7ECF0]">
        <div className="flex justify-between text-center flex-col w-full">
          <div>
            {MenuOption.map((option) => (
              <div
                className="flex flex-col items-center mt-3"
                key={option.name}
              >
                <div
                  className={`flex gap-3 items-center cursor-pointer px-4 py-2 rounded-lg ${
                    option.isActive ? "bg-purple-500" : ""
                  }`}
                  onClick={() => SideBarMenu(option.name)}
                >
                  <img
                    className={`w-8 h-8 ${
                      option.isActive ? "text-white" : "text-gray-500"
                    }`}
                    src={activeOption == option.name ? option.activeLogo : option.logo} // Use a different image for active state if needed
                    alt={option.name}
                  />
                  <p
                    className={`sm:hidden md:hidden lg:block text-sm sm:text-xl md:text-base lg:text-lg ${
                      activeOption == option.name ? "text-[#0391CF]" : "text-black"
                    }`}
                  >
                    {option.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div
            className="flex gap-3 justify-center items-center cursor-pointer px-4 py-2 rounded-lg"
            onClick={() => logout()}
          >
            <img className="w-8 h-8" src={Logout} alt="logout" />
            <p className="hidden sm:hidden md:hidden lg:block text-sm sm:text-xl md:text-base lg:text-lg">
              Log out
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
