import React from "react";
import ProfileImage from "../../Images/ProfileImage.svg";

function UserProfile() {
  const personalDetail = [
    "Pan Number: CV48799P",
    "Joining Date",
    "Aadhar Number: 5289784123",
  ];
  const address = [
    "State: Rajasthan",
    "District: Karauli",
    "Village/City: Sardarpur",
  ];
  const userJsonValue = localStorage.getItem("userValue");
  const userValue = userJsonValue ? JSON.parse(userJsonValue) : null;

  return (
    <>
        <div className="p-5 w-full">
          <div className="flex gap-10">
            <div className="bg-[#FAFAFA] w-1/4 p-10 flex flex-col justify-center items-center">
              <img src={ProfileImage} className="max-w-40" />
              <button className="rounded-full bg-[#CDAC2C] py-2 px-10 text-white mt-5">
                My Profile
              </button>
              <p className="text-base font-medium text-[#0391CF] mt-5">
                Name:
                <span className="text-base font-normal text-[#000000] ms-1">
                  {userValue.first_name} {userValue.middle_name}{" "}
                  {userValue.last_name}
                </span>
              </p>
              <p className="text-base font-medium text-[#0391CF] mt-1">
                Id:
                <span className="text-base font-normal text-[#000000] ms-1">
                  {userValue._id}
                </span>
              </p>
              <p className="text-base font-medium text-[#0391CF] mt-1">
                Email:
                <span className="text-base font-normal text-[#000000] ms-1">
                  {userValue.email}
                </span>
              </p>
              <p className="text-base font-medium text-[#0391CF] mt-1">
                Mobile No:
                <span className="text-base font-normal text-[#000000] ms-1">
                  {userValue.mobile_number}
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-10 w-full pe-3">
              <div className="bg-[#FAFAFA] p-5 w-full ms-2">
                <h1 className="text-xl font-medium">Personal Details</h1>
                {personalDetail.map((detail) => (
                  <li className="mt-3">{detail}</li>
                ))}
              </div>
              <div className="bg-[#FAFAFA] p-5 w-full ms-2">
                <h1 className="text-xl font-medium">Address</h1>
                {address.map((add) => (
                  <li className="mt-3">{add}</li>
                ))}
              </div>
            </div>
          </div>

          <div className="bg-[#FAFAFA] p-5 pb-10 w-full mt-5">
            <h1 className="text-xl font-medium">Document Upload</h1>
            <div className="flex gap-5 mt-5">
              <img src={userValue.pan_card_file}/>
            </div>
          </div>
        </div>
    </>
  );
}
export default UserProfile;