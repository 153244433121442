import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useLocation, useNavigate } from "react-router-dom";
import { FiChevronDown, FiX } from "react-icons/fi";
import { httpsRequest } from "../../Features/Auth/config";
import PopUp from "../PopUp/PopUp";
import Loader from "../Loader/Loader";
const CustomArrow = () => <FiChevronDown className="text-black-900" />;

function FarmEvaluation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: service_id } = location || {};
  const [farmerList, setFarmerList] = useState();
  const [searchList, setSearchList] = useState([]);
  const [formValues, setFormValues] = useState({
    aadhaar_card: "",
    farmer_id: "",
    farming_equipment_exist: false,
    farming_equipment_type: "",
    orchards_area: "",
    orchards_exist: false,
    orchard_type: [],
    service_id: service_id.service_id,
  });
  const [equipmentStatus, setEquipmentStatus] = useState(false);
  const [orchardStatus, setOrchardStatus] = useState(false);
  const [searchAadharBar, setSearchAadharBar] = useState(false);
  const [searchFarmIdBar, seSearchFarmIdBar] = useState(false);
  const [receiptPop, setReceiptPop] = useState(false);
  const [receiptId, setReceiptId] = useState();
  const [showLoader, setShowLoader] = useState(false)
  const orchardType = [
    { label: "Mango", value: "mango" },
    { label: "Banana", value: "banana" },
    { label: "Apple", value: "apple" },
    { label: "Orange", value: "orange" },
  ];

  useEffect(() => {
    GetAllFarmer();
  }, []);

  const GetAllFarmer = async () => {
    try {
      setShowLoader(true)
      const response = await httpsRequest(
        "v3/village-coordinator/farmer",
        "get"
      );
      if (response.status == 200) {
        setShowLoader(false)
        setFarmerList(response?.data);
      }
    } catch (error) {
      setShowLoader(false)
    }
  };

  const handleAadharSearch = (e) => {
    const { value } = e.target;
    setSearchAadharBar(true);
    const searchResult = farmerList.filter((item) =>
      item.aadhaar_number.toString().includes(Number(value))
    );
    setSearchList(searchResult);
  };

  const handleFarmIdSearch = (e) => {
    const { value } = e.target;
    seSearchFarmIdBar(true);
    const searchResult = farmerList.filter((item) =>
      item.farmer_id.toString().includes(Number(value))
    );
    setSearchList(searchResult);
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setFormValues({
      ...formValues,
      [name]: type == "number" ? Number(value) : value,
    });
    if (name == "farming_equipment_exist") {
      setEquipmentStatus(value);
      setFormValues({ ...formValues, [name]: Boolean(value) });
    }
    if (name == "orchards_exist") {
      setOrchardStatus(value);
      setFormValues({ ...formValues, [name]: Boolean(value) });
    }
  };

  const handleMultiSelectChange = (selectedItems) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      orchard_type: selectedItems.map((item) => item.value),
    }));
  };

  const FormSubmit = async () => {
    try {
      const orchard = formValues.orchard_type.join(" ");
      const formValue = { ...formValues, orchards_type: orchard };
      setShowLoader(true)
      const response = await httpsRequest(
        "v3/village-coordinator/service/farmer_valuation",
        "post",
        formValue
      );
      console.log("response from formSubmit of farm evaluation",response)
      if (response.status == 201) {
        setShowLoader(false)
        setReceiptId(response.data.farmer_service_id);
        setReceiptPop(true);
        // navigate("/services");
      }
    } catch (error) {
      setShowLoader(false)
    }
  };

  const closeModal = () => {
    setReceiptPop(false);
  };

  return (
    <>
    {showLoader && <Loader/>}
      {receiptPop && <PopUp closeModal={closeModal} receiptId={receiptId} />}
      <div className="flex flex-col w-full py-5 ps-5 pe-10">
        <div className="flex justify-between border-b border-color-[#F1F4F8]">
          <p className="font-medium text-xl mb-4">Valuation Card</p>
        </div>
        <div className="flex gap-x-5 w-full justify-start mt-8">
          <div>
            <p className="text-sm font-medium text-slate-700 my-2.5">
              Aadhar Card:
            </p>
            <div className="flex gap-10 mt-10 relative w-full">
              <input
                type="number"
                name="aadhaar_card"
                offFocus={() => setSearchAadharBar(false)}
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                placeholder="Search by Aadhar"
                onChange={handleInputChange}
              />

              {searchAadharBar && (
                <div className="shadow-md absolute top-12 bg-white w-full">
                  {searchList.length > 0 &&
                    searchList.map((farmer) => (
                      <p key={farmer.farmer_id} className="border-b-[1px] p-2 ">
                        {farmer.aadhaar_number}
                      </p>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Farmer Id:
            </p>
            <div className="flex gap-10 mt-10 relative w-full">
              <input
                type="number"
                name="farmer_id"
                offFocus={() => seSearchFarmIdBar(false)}
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                placeholder="Search by Farmer id"
                onChange={handleInputChange}
              />

              {searchFarmIdBar && (
                <div className="shadow-md absolute top-12 bg-white w-full">
                  {searchList.length > 0 &&
                    searchList.map((farmer) => (
                      <p key={farmer.farmer_id} className="border-b-[1px] p-2 ">
                        {farmer.farmer_id}
                      </p>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-x-5 w-full justify-start mt-6">
          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Equipment Exist:
            </p>
            <select
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              onChange={handleInputChange}
              name="farming_equipment_exist"
            >
              <option value="select">Select</option>
              <option value="yes">Yes</option>
              <option value="">No</option>
            </select>
          </div>

          {equipmentStatus && (
            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Equipment Type:
              </p>
              <select
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                onChange={handleInputChange}
                name="farming_equipment_type"
              >
                <option value="select">Select</option>
                <option value="Tractor">Tractor</option>
                <option value="Bullock Cart">Bullock Cart</option>
                <option value="Power Tiller">Power Tiller</option>
              </select>
            </div>
          )}
        </div>
        <div className="flex gap-x-5 w-full justify-start mt-6">
          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Orchards Exist:
            </p>
            <select
              className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              onChange={handleInputChange}
              name="orchards_exist"
            >
              <option value="select">Select</option>
              <option value="yes">Yes</option>
              <option value="">No</option>
            </select>
          </div>

          {orchardStatus && (
            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Orchards Types:
              </p>
              <MultiSelect
                className="min-w-96 p-3 border-solid p-0 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                options={orchardType}
                ArrowRenderer={CustomArrow}
                ClearSelectedIcon={null}
                value={orchardType.filter((option) =>
                  formValues.orchard_type.includes(option.value)
                )}
                onChange={handleMultiSelectChange}
                labelledBy="Select"
              />
            </div>
          )}
        </div>

        <div className="mt-8">
          <p className="block text-sm font-medium text-slate-700 my-2.5">
            Orchards Area:
          </p>
          <input
            type="text"
            name="orchards_area"
            onChange={handleInputChange}
            className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
          />
        </div>

        <div className="flex justify-center gap-5 mt-5">
          <button
            type="button"
            onClick={() => navigate("/services")}
            className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={FormSubmit}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default FarmEvaluation;
