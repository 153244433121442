import React, { useEffect, useState } from "react";
import { httpsRequest } from "../../Features/Auth/config";
import { useNavigate } from "react-router-dom";

function NotificationComponent() {
    const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState([]);

  const headerOption = [
    "Farmer Name",
    "Father Name",
    "Mobile Number",
    "Aadhar Number",
    "Village Name",
  ];
  useEffect(() => {
    NotificationList();
  }, []);

  const NotificationList = async () => {
    try {
      const response = await httpsRequest(
        "district-bdo/get-all-crops-valuation-form",
        "get"
      );
      if (response.status === 200) {
        setNotificationList(response.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handelNotification = (formDetail) => {
        navigate("/crop-valuation-certificate",{state : formDetail})
  }

  return (
    <>
      <table className="table border-2 border-[#DAE1E9] mt-5 w-full">
        <thead>
          <tr>
            {headerOption.map((header) => (
              <th
                scope="col"
                className="px-6 py-3 text-[#383737] capitalize tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {notificationList.map((list) => (
            <tr className="border-2 border-[#DAE1E9] text-center min-h-14 cursor-pointer" onClick={() => handelNotification(list)}>
              <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                {list.farmer_name}
              </td>
              <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                {list.farmer_details.father_name}
              </td>
              <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                {list.farmer_details.mobile_number}
              </td>
              <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                {list.aadhaar_number}
              </td>
              <td className="px-6 py-3 text-sm font-medium text-[#383737] capitalize tracking-wider">
                {list.farmer_details.village_name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default NotificationComponent;
