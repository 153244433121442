import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import { ToastContainer, toast } from "react-toastify";
import Webcam from "react-webcam";
import { useDropzone } from 'react-dropzone';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const Base_Url = process.env.React_App_Base_Url;

function AddFarmer() {
  const token = sessionStorage.getItem("token")
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [previewProfileImage, setPreviewProfileImage] = useState(null);
  const [farmerDetail, setFarmerDetail] = useState({
    address: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    category: "",
    gender: "",
    dob: "",
    mobile_number: "",
    email: "",
    pan_number: "",
    aadhaar_number: "",
    father_name: "",
    mother_name: "",
    city_name: "",
    pin_number: "",
    profile_img:""
  });
  const [formErrors, setFormErrors] = useState({
    address: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    category: "",
    gender: "",
    dob: "",
    mobile_number: "",
    email: "",
    aadhaar_number: "",
    pan_number: "",
    father_name: "",
    mother_name: "",
    city_name: "",
    pin_number: "",
    profile_img:""
  });
  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState({});
  
  useEffect(() => {
    if (hasError) {
      toast.error(errors, {
        position: "top-right",
        autoClose: 8000, // 8 seconds
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [hasError]);

  const capture = useCallback(() => {
    const capturedImage = webcamRef.current.getScreenshot();
    setImageSrc(capturedImage);
  }, [webcamRef]);

  // Handle file drop (file upload)
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result); 
        setFarmerDetail((prev) => ({ ...prev, profile_img: reader.result })); 
      };
      reader.readAsDataURL(file);
    },
  });

  const FormDetail = (e) => {
    const { name, value, type } = e.target;
    setFarmerDetail({
      ...farmerDetail,
      [name]: type === "number" ? Number(value) : value,
    });
  };

  const handleFileSelect = (name, file) => {
    console.log("name",name,"file",file)
    setFarmerDetail({ ...farmerDetail, [name]: file });
    setPreviewProfileImage(URL.createObjectURL(file));  
  };

  const handleButtonClick = () => {
    console.log("test value");
    fileInputRef.current.click();
  };

 

  const FormSubmit = async () => {
    
    try {
      const hasErrors = Object.keys(farmerDetail).some(
        (key) => farmerDetail[key] == "" || 0
      );

      if (hasErrors) {
        const updatedErrors = Object.keys(formErrors).reduce((acc, key) => {
          if (farmerDetail[key] == "") {
            acc[key] = `${key} can't be empty`;
          }
          return acc;
        }, {});
        setFormErrors({ ...formErrors, ...updatedErrors });
      } else {  

        // const formData = new FormData();

        // // formData.append("address",farmerDetail.address);
        // formData.append("first_name",farmerDetail.first_name);
        // formData.append("middle_name",farmerDetail.middle_name);
        // formData.append("last_name",farmerDetail.last_name);
        // formData.append("category",farmerDetail.category);
        // formData.append("gender",farmerDetail.gender);
        // formData.append("dob",farmerDetail.dob);
        // formData.append("mobile_number",farmerDetail.mobile_number);
        // formData.append("email",farmerDetail.email);
        // formData.append("pan_number",farmerDetail.pan_number);
        // formData.append("aadhaar_number",farmerDetail.aadhaar_number);
        // formData.append("father_name",farmerDetail.father_name);
        // formData.append("mother_name",farmerDetail.mother_name);
        // formData.append("city_name",farmerDetail.city_name);
        // formData.append("address",farmerDetail.address);
        // formData.append("pin_number",farmerDetail.pin_number);
        // formData.append("profile_img",farmerDetail.profile_img);

        // console.log("formdata---==-==--==-=-=",farmerDetail.address)
        // const response = await axios.post(
        //   `${Base_Url}/v3/village-coordinator/farmer`,
        //   formData,
        //   { headers: { Authorization: `Bearer ${token}` } }
        // );

        const formData = new FormData();

        // Ensure each field in farmerDetail has a value before appending
        formData.append("first_name", farmerDetail.first_name || "");
        formData.append("middle_name", farmerDetail.middle_name || "");
        formData.append("last_name", farmerDetail.last_name || "");
        formData.append("category", farmerDetail.category || "");
        formData.append("gender", farmerDetail.gender || "");
        formData.append("dob", farmerDetail.dob || "");
        formData.append("mobile_number", farmerDetail.mobile_number || "");
        formData.append("email", farmerDetail.email || "");
        formData.append("pan_number", farmerDetail.pan_number || "");
        formData.append("aadhaar_number", farmerDetail.aadhaar_number || "");
        formData.append("father_name", farmerDetail.father_name || "");
        formData.append("mother_name", farmerDetail.mother_name || "");
        formData.append("city_name", farmerDetail.city_name || "");
        formData.append("address", farmerDetail.address || "");
        formData.append("pin_number", farmerDetail.pin_number || "");
        
        // Check if profile_img is a valid File object
        if (farmerDetail.profile_img instanceof File) {
          formData.append("profile_img", farmerDetail.profile_img);
        } else {
          console.warn("Invalid profile image");
        }
        
        // Log formData keys and values
        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }
        
        const response = await httpsRequest(
          "v3/village-coordinator/farmer",
          "post",
          formData
        );
        if (response.status == 200) {
          navigate("/dashboard");
        } else {
          setErrors(response.response.data.message);
          setHasError(true);
        }
      }
    } catch (error) {
      setErrors(error?.response?.data?.message);
      setHasError(true);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="py-5 ps-5 pe-10">
        {/* <p className="font-medium text-xl">Farmer Information</p> */}
        <p className="text-3xl font-medium mt-5">Farmer Details</p>

        <p className="font-medium text-xl my-8">Personal Details</p>

        <div>
          <h2>Upload or Capture a Photo</h2>

          {/* Webcam Section */}
          <div>
            <h3>Take a Photo from Webcam</h3>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
            <button onClick={capture}>Capture Photo</button>
          </div>

          {/* File Upload Section */}
          <div className="border border-dashed border-gray-300 w-32 h-32 flex items-center justify-center relative"> 
            {previewProfileImage ? (
              <img
                src={previewProfileImage}
                alt="Profile Preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <span className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center text-gray-500">
                Profile Photo
              </span>
            )}

            <input
              id="profile_img"
              type="file"
              ref={fileInputRef}
              name="profile_img"
              accept="image/*"
              className="hidden"
              onChange={(e) =>
                handleFileSelect("profile_img", e.target.files[0])
              }
            />
            <button
              onClick={handleButtonClick}
              className="absolute inset-0 w-full h-full bg-gray-200 opacity-0 hover:opacity-50 focus:opacity-50"
            >
              <span className="sr-only" htmlFor="profile_img">
                Change Profile Photo
              </span>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-y-5 gap-x-10 mt-9">
          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Frist Name:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="first_name"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.first_name && (
              <p className="text-xs text-red-500">{formErrors.first_name}</p>
            )}
          </div>
          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Middle Name:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="middle_name"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.middle_name && (
              <p className="text-xs text-red-500">{formErrors.middle_name}</p>
            )}
          </div>
          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Last Name:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="last_name"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.last_name && (
              <p className="text-xs text-red-500">{formErrors.last_name}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Category:
            </p>
            <select
              onChange={(e) => FormDetail(e)}
              name="category"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            >
              <option value="">Select</option>
              <option value="general">General</option>
              <option value="obc">OBC</option>
              <option value="st">ST</option>
              <option value="sc">SC</option>
            </select>
            {!farmerDetail.category && (
              <p className="text-xs text-red-500">{formErrors.category}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Gender:
            </p>
            <select
              onChange={(e) => FormDetail(e)}
              name="gender"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
            >
              <option value="">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {!farmerDetail.gender && (
              <p className="text-xs text-red-500">{formErrors.gender}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              DOB:
            </p>
            <input
              type="date"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="dob"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.dob && (
              <p className="text-xs text-red-500">{formErrors.dob}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Mobile Number:
            </p>
            <input
              type="number"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="mobile_number"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.mobile_number && (
              <p className="text-xs text-red-500">{formErrors.mobile_number}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Email:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="email"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.email && (
              <p className="text-xs text-red-500">{formErrors.email}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Aadhar Number:
            </p>
            <input
              type="number"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="aadhaar_number"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.aadhaar_number && (
              <p className="text-xs text-red-500">
                {formErrors.aadhaar_number}
              </p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Pan Number:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="pan_number"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.pan_number && (
              <p className="text-xs text-red-500">{formErrors.pan_number}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Father Name:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="father_name"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.father_name && (
              <p className="text-xs text-red-500">{formErrors.father_name}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Mother Name:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="mother_name"
              onChange={(e) => FormDetail(e)}
            />
            {!farmerDetail.mother_name && (
              <p className="text-xs text-red-500">{formErrors.mother_name}</p>
            )}
          </div>
        </div>

        <p className="font-medium text-xl mt-12">Address Details</p>
        <div className="grid grid-cols-3 gap-y-5 gap-x-10 mt-9">
          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Address:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="address"
              onChange={(e) => FormDetail(e)}
              placeholder="Address"
            />
            {!farmerDetail.address && (
              <p className="text-xs text-red-500">{formErrors.address}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              City:
            </p>
            <input
              type="text"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="city_name"
              onChange={(e) => FormDetail(e)}
              placeholder="City Name"
            />
            {!farmerDetail.city_name && (
              <p className="text-xs text-red-500">{formErrors.city_name}</p>
            )}
          </div>

          <div className="block">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Pin:
            </p>
            <input
              type="number"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="pin_number"
              onChange={(e) => FormDetail(e)}
              placeholder="Pin"
            />
            {!farmerDetail.pin_number && (
              <p className="text-xs text-red-500">{formErrors.pin_number}</p>
            )}
          </div>
        </div>

        <div className="flex justify-center gap-5 mt-5">
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={FormSubmit}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default AddFarmer;
