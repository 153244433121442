import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FarmerTable from "./FarmerTable";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";

function FarmerHistory() {
  const location = useLocation();
  const { farmerList } = location.state || {};
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState([]);
  const [searchBar, setSearchBar] = useState(false);
  const headerOption = ["Name","Farmer ID", "Contact", "Gmail", "District", "Action"];
  const actionOption = ["Add Farm", "Update Farmer", "View Farm", "Delete"];
  const [showLoader, setShowLoader] = useState(false)

  const DeleteFarmer = async (userDetail) => {
    try {
      setShowLoader(true)
      const response = await httpsRequest(
        `v3/village-coordinator/farmer/${userDetail.farmer_id}`,
        "delete"
      );
      if (response.status === 200) {
        setShowLoader(false)
        alert(`${userDetail.first_name} is Deleted`);
        navigate("/dashboard");
      }
    } catch (error) {
      setShowLoader(false)
      console.error("Error deleting Item", error);
    }
  };

  const handleInputSearch = (e) => {
    const { value } = e.target;
    setSearchBar(true);
    const searchResult = farmerList.filter((item) =>
      item.first_name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchList(searchResult);
  };

  return (
    <>
    {showLoader && <Loader/>}
      <div className="flex flex-col w-full py-5 ps-5 pe-10 ">
        <p className="font-medium text-xl">Farmer History</p>

        <div className="flex gap-10 mt-10 relative w-full">
          <div className="flex items-center gap-2 border-[1px] p-1 rounded-lg w-full">
            <input
              onFocus={() => setSearchBar(false)}
              className="outline-none w-full p-1"
              placeholder="Search"
              onChange={handleInputSearch}
            />
          </div>

          {searchBar && (
            <div className="shadow-md absolute top-12 bg-white w-full">
              {searchList.length > 0 &&
                searchList.map((farmer) => (
                  <p key={farmer.farmer_id} className="border-b-[1px] p-2 ">
                    {farmer.first_name}
                  </p>
                ))}
            </div>
          )}
        </div>

        <FarmerTable
          data={farmerList}
          headerOption={headerOption}
          action={true}
          itemsPerPage={10}
          DeleteUser={DeleteFarmer}
          options={actionOption}
        />
      </div>
    </>
  );
}

export default FarmerHistory;
