import React, { useState } from "react";
import LoginBG from "../../Images/RegistrationBG.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { httpsRequest } from "../../Features/Auth/config";
import Cookies from "js-cookie";
import Loader from "../Loader/Loader";
import Eye from "../../Images/Eye.svg";
import EyeClose from "../../Images/EyeClose.svg";

function Login() {
  const location = useLocation();
  const { state: role } = location || {};
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({ userName: "", password: "" });
  const [error, setError] = useState({ userName: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const FormDetail = (e) => {
    const { name, value } = e.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  const FormSubmit = async () => {
    try {
      if (userDetail.userName == "") {
        setError({ ...error, userName: "User Name can't be empty" });
      } else if (userDetail.password == "") {
        setError({ ...error, password: "Password Can't be empty" });
      } else {
        const formData = {
          role: Number(role),
          email: userDetail.userName,
          password: userDetail.password,
          rememberMe,
        };
        setShowLoader(true);
        const response = await httpsRequest("auth/login", "post", formData);
        if (response.status == 200) {
          setShowLoader(false);
          localStorage.setItem("userValue", JSON.stringify(response?.data));
          localStorage.setItem("role", JSON.stringify(response?.data?.role));
          if (rememberMe) {
            Cookies.set("authToken", response?.token, {
              expires: 7,
              secure: true,
              sameSite: "Strict",
            });
          } else {
            sessionStorage.setItem("token", response?.token);
          }
          navigate("/dashboard", { replace: true });
        }
      }
    } catch (error) {
      setShowLoader(false);
      console.log("Getting Error in Login User", error);
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className="flex justify-between">
        <div className="w-full max-h-full">
          <img src={LoginBG} className="w-dvw h-dvh" />
        </div>

        <div className="registrationBackground w-1/2 flex-col flex items-center justify-center bg-gray-100 w-full">
          <h1 className="text-4xl">LOGIN</h1>

          <div className="block mt-5 ">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              UserName:
            </p>

            <input
              type="email"
              className="min-w-96 p-3  border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="userName"
              onChange={(e) => FormDetail(e)}
              placeholder="Enter Username"
            />
            {!userDetail.userName && (
              <p className="text-xs text-red-500">{error.userName}</p>
            )}
          </div>

          <div className="block mt-3">
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Password:
            </p>
            <div className="relative">
              <input
                type={showPassword ? "password" : "text"}
                className="min-w-96 p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                name="password"
                placeholder="Enter Password"
                onChange={(e) => FormDetail(e)}
              />
              {showPassword ? (
                <img
                  onClick={()=>setShowPassword(false)}
                  src={Eye}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              ) : (
                <img
                  onClick={()=>setShowPassword(true)}
                  src={EyeClose}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              )}
            </div>
            {!userDetail.password && (
              <p className="text-xs text-red-500">{error.password}</p>
            )}
          </div>

          <div className="flex justify-between min-w-96 mt-5">
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <p className="text-[#6E7C87] text-sm">Remember Me</p>
            </div>
            <Link className="text-[#6E7C87] text-sm">Forgot Password?</Link>
          </div>

          <button
            type="submit"
            className="rounded-lg bg-orange-500 py-2 px-10 text-white mt-5"
            onClick={FormSubmit}
          >
            Login
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;
