import React, { useEffect, useState } from "react";
import GraphIcon from "../../Images/GraphIcon.svg";
import { useNavigate } from "react-router-dom";
import FarmerTable from "../Farmer/FarmerTable";
import { httpsRequest } from "../../Features/Auth/config";
import PopUp from "../PopUp/PopUp";
const Base_Url = process.env.React_App_Base_Url;

function VillageCoordinator() {
  const navigate = useNavigate();
  const [farmerList, setFarmerList] = useState([]);
  const [popVisibility, setPopVisibility] = useState(false);
  const villageInformation = [
    { fieldName: "Total District B.D.O", value: "500" },
    { fieldName: "Total Farmer", value: "6500" },
    { fieldName: "Total Village Cordinator", value: "800" },
    { fieldName: "Farm Area in Bigha", value: "800" },
  ];

  const farmerHeaderOption = [
    "Name",
    "Farmer ID",
    "Contact",
    "Email",
    "District",
  ];
  const farmHeaderOption = ["Total Farm", "Khasra NO.", "Farm Size", "Farm ID"];

  useEffect(() => {
    GetFarmerDetail();
    // GetAllUser();
  }, []);
  const GetFarmerDetail = async () => {
    try {
      const response = await httpsRequest(
        "v3/village-coordinator/farmer",
        "get"
      );
      if (response.status == 200) {
        setFarmerList(response?.data);
      }
    } catch (error) {
      console.error("error in getting farmer list", error);
    }
  };
  // const GetAllUser = async () => {
  //   try {
  //     const response = await httpsRequest("v1/sa/get-total-user","get")
  //     console.log("response---------=====>>>>>>>>",response)
  //   } catch (error) {

  //   }
  // }
  return (
    <div className="flex flex-col w-full py-5 ps-5 pe-10 sm:p-4 md:p-3 ">
      <div className="flex justify-end gap-2">
        <button
          className="rounded-lg bg-[#75A651] flex py-1.5 md:py-2 px-3 md:px-4 text-xs md:text-base text-white gap-2"
          type="button"
          onClick={() => navigate("/add-farmer")}
        >
          <div className="flex items-center justify-center w-4 h-4 md:w-5 md:h-5 bg-green-800 rounded-full">
            <span className="text-xs md:text-base">+</span>
          </div>
          Add Farmer
        </button>
      </div>
      {popVisibility && <PopUp />}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 mt-9">
        {villageInformation.map((detail) => (
          <div
            key={detail.fieldName}
            className="border-2 border-[#75A651] flex justify-between py-3 md:py-4 px-3 md:px-4 rounded-xl"
          >
            <div>
              <p className="text-3xl md:text-4xl lg:text-5xl">{detail.value}</p>
              <p className="mt-1.5 md:mt-2.5 text-sm md:text-base">
                {detail.fieldName}
              </p>
            </div>
            <img
              src={GraphIcon}
              className="max-w-12 md:max-w-14 lg:max-w-16 max-h-12 md:max-h-14 lg:max-h-16"
            />
          </div>
        ))}
      </div>

      <div className="flex flex-col items-end mt-6">
        <FarmerTable
          headerOption={farmerHeaderOption}
          data={farmerList}
          itemsPerPage={5}
        />
        <p
          className="text-xs sm:text-sm md:text-base text-[#FF9933] mt-2 cursor-pointer"
          onClick={() =>
            navigate("/farmer-history", { state: { farmerList: farmerList } })
          }
        >
          View All
        </p>
      </div>
    </div>
  );
}

export default VillageCoordinator;
