import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { httpsRequest } from "../../Features/Auth/config";
import Loader from "../Loader/Loader";


function AddFarm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: value } = location || {};
  const [totalFarm, setTotalFarm] = useState();
  const [formData, setFormData] = useState({});
  const [farms, setFarms] = useState([]);
  const [errors, setErrors] = useState({});
  const [formArr, setFormArr] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [irrigationStatus, setIrrigationStatus] = useState([]);
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (hasError) {
      toast.error(errors, {
        position: "top-right",
        autoClose: 8000, // 8 seconds
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [hasError]);

  useEffect(() => {
    const initialFarmsData = Array(totalFarm).fill({});
    setFarms({ ...farms, farmsData: initialFarmsData });
  }, [totalFarm]);

  const handleNumFields = (value) => {
    setTotalFarm(Number(value));
    const newFormdata = {};
    for (let i = 0; i < Number(value); i++) {
      newFormdata[`field`] = i;
    }
    setFormData(newFormdata);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "irrigation_status") {
      updatedFormData = { ...formData, [name]: Boolean(value) };
    }

    const updatedFarmsData = [...farms.farmsData];
    updatedFarmsData[index] = updatedFormData;
    setFormData(updatedFormData);

    setFormArr(updatedFarmsData);
    setFarms({ ...farms, farmsData: updatedFarmsData });

    if (name === "irrigation_status") {
      const newIrrigationStatus = [...irrigationStatus];
      newIrrigationStatus[index] = value === "true";
      setIrrigationStatus(newIrrigationStatus);
    }
  };

  const FormSubmit = async () => {
    try {
      setHasError(hasError);
      const farmer_name = `${value.first_name} ${value.middle_name} ${value.last_name}`;
      const farmer_id = value.farmer_id;

      // Filter formArr to only include fields if irrigation_status is "yes"
      const filteredFormArr = formArr.map((farm, index) => {
        if (irrigationStatus[index]) {
          // Include water_resource field
          return farm;
        } else {
          // Exclude water_resource field
          const { water_resource, ...rest } = farm;
          return rest;
        }
      });

      const formValue = { farms: filteredFormArr, farmer_name, farmer_id };
      setShowLoader(true)
      const response = await httpsRequest(
        "v3/village-coordinator/farm",
        "post",
        formValue
      );

      if (response.status === 200) {
        setShowLoader(false)
        setHasError(false);
        navigate("/dashboard");
      }
    } catch (error) {
      setShowLoader(false)
      setErrors(error?.response?.data?.message);
      setHasError(true);
    }
  };

  return (
    <>
    {showLoader && <Loader/>}
      <ToastContainer />
      <div className="py-5 ps-5 pe-10">
        <p className="font-medium text-xl">Farm Information</p>
        <p className="text-3xl font-medium mt-16 mb-2">Farm Details</p>

        <div className="mt-2.5 grid grid-cols-3 gap-5">
          <div>
            <p className="block text-sm font-medium text-slate-700 my-2.5">
              Total Farm:
            </p>
            <input
              type="number"
              className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              name="totalFarm"
              onChange={(e) => handleNumFields(e.target.value)}
              placeholder="Number of Farm"
            />
          </div>
        </div>

        {[...Array(totalFarm)].map((_, index) => (
          <div
            className="grid grid-cols-3 gap-5 border-y-2 my-4 py-2"
            key={index}
          >
            <div className="w-full">
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Khasra Number:
              </p>
              <input
                type="text"
                name="khasra_number"
                onChange={(e) => handleInputChange(e, index)}
                className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              />
            </div>

            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Farm Size:
              </p>
              <input
                type="text"
                name="area"
                onChange={(e) => handleInputChange(e, index)}
                className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
              />
            </div>

            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Soil Type:
              </p>
              <select
                className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                onChange={(e) => handleInputChange(e, index)}
                name="soil_type"
              >
                <option value="">select</option>
                <option value="red">Red</option>
                <option value="black">Black</option>
              </select>
            </div>

            <div>
              <p className="block text-sm font-medium text-slate-700 my-2.5">
                Irrigation Status:
              </p>
              <select
                className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                onChange={(e) => handleInputChange(e, index)}
                name="irrigation_status"
              >
                <option value="">select</option>
                <option value="true">Yes</option>
                <option value="">No</option>
              </select>
            </div>

            {irrigationStatus[index] && (
              <div>
                <p className="block text-sm font-medium text-slate-700 my-2.5">
                  Water Resource:
                </p>
                <input
                  type="text"
                  name="water_resource"
                  onChange={(e) => handleInputChange(e, index)}
                  className="w-full p-3 border-solid border-1 border-indigo-200 h-12 rounded-lg border-[1px] border-[#DAE1E9]"
                />
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-center gap-5 mt-5">
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="rounded-lg bg-[#6E7C87] p-2 px-3 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={FormSubmit}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default AddFarm;
